import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ACCESS_RIGHTS, useAcl } from '@utils/acl';
import { useAclOms } from '@utils/acl/useAclOms';
import type { Paths, ReturnedMappedRights } from '@utils/hooks/useOmsRights';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    DropdownOption,
    TableDropdown as TableDropdownComponent,
    TableDropdownProps as SezaneTableDropdownProps,
} from 'sezane-components';

export interface TableDropdownProps extends SezaneTableDropdownProps {
    options: Array<DropdownOption & { accessRight?: ACCESS_RIGHTS; accessRightOms?: Paths<ReturnedMappedRights> }>;
    icon?: IconProp;
    label?: string;
}

const TableDropdown = ({ options, icon = 'wrench', label, ...props }: TableDropdownProps) => {
    const { isGranted } = useAcl();

    const { isGranted: isGrantedOms } = useAclOms();
    const filteredOptions = options.filter(option =>
        option.accessRight || option.accessRightOms
            ? option.accessRight
                ? isGranted(option.accessRight)
                : isGrantedOms(option.accessRightOms!)
            : true
    );

    return !!filteredOptions.length ? (
        <TableDropdownComponent
            icon={icon}
            label={label && <FormattedMessage id={label} />}
            options={filteredOptions}
            {...props}
        />
    ) : null;
};

export default TableDropdown;
