import { OMS } from '@types';
import { updateActionCreator, withNoSite, ID_PLACEHOLDER } from '../../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator: actionCreatorCustomer } = updateActionCreator(
    name,
    schema,
    `${baseUrl}/${ID_PLACEHOLDER}/clients`
);
const { actionCreator: actionCreatorEmployees } = updateActionCreator(
    name,
    schema,
    `${baseUrl}/${ID_PLACEHOLDER}/employees`
);
const { actionCreator: actionCreatorInfluence } = updateActionCreator(
    name,
    schema,
    `${baseUrl}/${ID_PLACEHOLDER}/influence`
);
const { actionCreator: actionCreatorFriendsAndFamily } = updateActionCreator(
    name,
    schema,
    `${baseUrl}/${ID_PLACEHOLDER}/friends_family`
);
const { actionCreator: actionCreatorRetail } = updateActionCreator(name, schema, `${baseUrl}/${ID_PLACEHOLDER}/retail`);

export const TYPES = types;

export default (id: string, data: OMS.VoucherCampaign) => {
    debugger;
    const actionCreatorMap = {
        clients: actionCreatorCustomer,
        employees: actionCreatorEmployees,
        influence: actionCreatorInfluence,
        friends_family: actionCreatorFriendsAndFamily,
        retail: actionCreatorRetail,
    };

    const recipientType = data.recipientType;
    const actionCreator = actionCreatorMap[recipientType];

    if (!actionCreator) {
        throw new Error(`Invalid recipient type: ${recipientType}`);
    }

    return withNoSite(actionCreator(id, data));
};
