import { useRightsContext } from '@contexts/RightsContext';
import { Paths, ReturnedMappedRights } from '../hooks/useOmsRights';
import { ROUTES_ACCESS_RIGHTS_OMS } from './routesAccessRightsOms';
import routesNames from '@config/routesNames';
import { matchPath } from 'react-router';

export const getMatchRoute = (path: string) => {
    const routes = Object.values(routesNames).sort((a, b) => (a.length > b.length ? -1 : 1));
    const matchRoute = matchPath(path, { path: routes });
    return matchRoute;
};
export const isOmsRoute = (path: string) => {
    const matchRoute = getMatchRoute(path);
    if (!matchRoute) return false;
    return Object.keys(ROUTES_ACCESS_RIGHTS_OMS).includes(matchRoute.path);
};

export const useAclOms = () => {
    const { rights } = useRightsContext();

    const isGranted = (accessRight: Paths<ReturnedMappedRights>) => {
        const [group, key] = accessRight.split('.');
        const sectionRights = rights[group as keyof typeof rights];
        if (sectionRights) {
            return !!sectionRights[key as keyof typeof sectionRights];
        }
        return false;
    };

    const isPathAllowedOms = (path: string) => {
        const matchRoute = getMatchRoute(path);
        if (!matchRoute) return false;
        const accessRight = ROUTES_ACCESS_RIGHTS_OMS[matchRoute.path];

        if (!accessRight) {
            if (process.env.NODE_ENV === 'development' && path !== '/') {
                // eslint-disable-next-line no-console
                console.log('No access rights defined for path', path);
            }
            return true;
        }

        return isGranted(accessRight);
    };

    return { isGranted, isPathAllowedOms };
};
