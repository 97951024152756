type getNestedUnionValues<T> = T extends any ? T[keyof T] : never;
type getNestedUnionKeys<T> = T extends any ? keyof T : never;
export type RightsMapping = typeof rightsMapping;

export type RightsMappingGroup = keyof RightsMapping;

export type RightsMappingKey = {
    [K in RightsMappingGroup]: getNestedUnionValues<typeof rightsMapping[K]>;
}[RightsMappingGroup];

export type RightsMappingUuid = {
    [K in RightsMappingGroup]: getNestedUnionKeys<typeof rightsMapping[K]>;
}[RightsMappingGroup];

export interface OmsRights {
    group: RightsMappingGroup;
    uuid: RightsMappingUuid;
    description: string;
}

type Reverser<T extends Record<PropertyKey, RightsMappingKey>> = {
    [P in keyof T as T[P]]: P;
};

export const getRightsList = (arr: OmsRights[]) =>
    arr.reduce((obj, { uuid, group }) => {
        return rightsMapping[group]
            ? {
                  ...obj,
                  [group]: {
                      ...obj[group],
                      // @ts-expect-error
                      ...(rightsMapping[group][uuid] && { [rightsMapping[group][uuid]]: uuid }),
                  },
              }
            : obj;
    }, {} as { [group in RightsMappingGroup]: Reverser<RightsMapping[group]> });

export const rightsMapping = {
    retail: {
        '365671fa-5de1-11ee-908a-7ba104c4a2a4': 'SET_CASHIER_PIN_CODE_RIGHTS',
        'c3da09e7-9de5-48d4-8a95-40fc07df51d0': 'GET_USER_ALERTS_LIST_RIGHTS',
        '9758e29a-5867-420e-9d81-f6e017daba4a': 'CREATE_USER_CREDIT_RIGHTS',
        'a73a9d48-7c7f-45fd-af08-a6630640951d': 'GET_USER_CREDITS_LIST_RIGHTS',
        'a892f025-856e-4158-90c8-e3289291a379': 'ADD_USER_ADDRESS_RIGHTS',
        'f4e493fe-29a1-4cad-8957-e1bb22eb4fe0': 'DELETE_USER_ADDRESS_RIGHTS',
        'c1e1fb85-3738-4060-8ffd-fec52568a6ee': 'UPDATE_USER_ADDRESS_RIGHTS',
        '1965bfcb-3d2a-46a3-a5b9-d41b51109dd0': 'CREATE_USER_RIGHTS',
        'b23a8177-4e51-4251-88bb-c84b362d0aea': 'LIST_USERS_RIGHTS',
        'fc7c62ce-b1a0-4d8f-b97a-54d1c6b13481': 'GET_USER_RIGHTS',
        'a9026a84-6dff-40a9-af86-03c5c2a7aedb': 'UPDATE_USER_RIGHTS',
        'adeb095a-5936-452e-a65b-d4dcb27414be': 'GET_USER_WHISHLIST_RIGHTS',
        '97a08819-ad89-4ec2-a353-69fff4c303fb': 'CANCEL_STUART_JOB_FOR_LMS_ORDER_RIGHTS',
        'f9e19484-cdc9-4973-a671-d3b3881061d1': 'CREATE_NEW_ORDER_FROM_CART_RIGHTS',
        '8312b595-0484-436e-935f-8619ee6142eb': 'CREATE_STUART_JOB_FOR_LMS_ORDER_RIGHTS',
        'c5bd7049-be21-49d5-914e-c5eab46f5823': 'LIST_VALIDATED_LMS_ORDERS_RIGHTS',
        '3e332d0b-9661-4b09-8d39-c8bb9b6ca89a': 'UPDATE_ORDER_TO_IN_PREPARATION_RIGHTS',
        '1a6a41d0-3093-412b-a448-84abac4fb0ec': 'UPDATE_ORDER_TO_SHIPPED_RIGHTS',
        '9ea6d46c-29fc-4f90-9773-5027ab74fb14': 'UPDATE_ORDER_TO_DELIVERED_CUSTOMER_RIGHTS',
        '92da2d60-2898-4f9c-811e-c0278222b312': 'LIST_USER_ORDERS_RIGHTS',
        '4d2b52c6-ff0f-4078-9f78-6e08ce8a0acf': 'GET_ORDER_DETAILS_RIGHTS',
        '2ee74d5d-de93-486b-9297-228b368d5979': 'GET_SHOP_DAILY_STATS_RIGHTS',
        'c4f6b5a0-5b0a-4b9a-9b9a-9b9a9b9a9b9a': 'GET_SHOP_DAILY_STATS_BY_EMAIL_RIGHTS',
        'fd93f951-958c-44c9-86bc-953643bba1e3': 'CREATE_SHOP_PAYMENT_SESSIONS_RIGHTS',
        'd61cb2f6-810f-4984-bea5-51d185033204': 'CREATE_RACK_RIGHTS',
        '52faa01f-5882-479c-be3b-370cd943bf2c': 'LIST_RACKS_RIGHTS',
        'f11bc497-c47c-430a-89ac-c78a9c04e000': 'UPDATE_RACKS_EANS_STOCK_RIGHTS',
        '0eb1a6f2-497d-4172-8389-31ce60873961': 'SEARCH_RETAIL_STOCK_RIGHTS',
    },
    widget: {
        '3656702e-5de1-11ee-9088-c74423345b83': 'GET_BEST_WIDGETS_RIGHTS',
        '365671be-5de1-11ee-9089-dbeb24ac6827': 'TURNOVER_WIDGET_RIGHTS',
    },
    cart: {
        'b92fd080-a9f7-436a-8738-4329a0de19d8': 'LIST_CARTS_RIGHTS',
        '5ccadf7b-47ab-404a-8fb6-c87d68c92618': 'GET_CART_RIGHTS',
        '07918724-a81e-491c-9840-f8565ca278b2': 'LIST_COMMERCIAL_OPE_LINKED_TO_CART_RIGHTS',
    },
    mass_import: {
        'dcf83718-993d-4ade-ae5e-dbff829790da': 'LIST_AVAILABLE_SHIPPING_MODES_RIGHTS',
        'ece3b000-9d95-4963-bd91-1f83b9f10d49': 'CREATE_MASS_IMPORT_RIGHTS',
        '7e43f337-e4d2-4dd1-976c-9b1929fe3e1d': 'DELETE_CART_LINE_ITEM_CUSTOMISATION_RIGHTS',
        'cd9102ac-638d-408a-a5d6-6117e35a162a': 'DELETE_CART_GIFT_MESSAGE_RIGHTS',
        '2dfcf62f-d606-4377-b2d7-623389ee36c8': 'GET_CART_LINE_ITEM_CUSTOMISATION_RIGHTS',
        '8b46ee64-3b03-43ac-85c4-8cb227e5d047': 'LIST_MASS_IMPORT_CARTS_RIGHTS',
        '84b09600-6cb6-4e19-97a5-2a6eeab82466': 'ADD_OR_UPDATE_CART_BILLING_ADDRESS_RIGHTS',
        '0d709269-203f-42e8-aa8e-b15b5fdc6c33': 'ADD_OR_UPDATE_CART_LINE_ITEM_CUSTOMISATION_RIGHTS',
        '5dc98e7b-4305-4b06-935d-070c1058667b': 'ADD_OR_UPDATE_CART_GIFT_MESSAGE_RIGHTS',
        'd0299513-aa3c-4cb7-9db8-42ec574a1d34': 'ADD_OR_UPDATE_CART_SHIPPING_ADDRESS_RIGHTS',
        'f5c61e32-a3b8-45bb-8e94-f27fe6908b95': 'GENERATE_CARTS_ORDERS_RIGHTS',
    },
    reorder: {
        '62e5f961-cc33-4fea-b99f-2d499ce35c94': 'ADD_BILLING_ADDRESS_RIGHTS',
        '28eb8d32-c560-42ec-abbb-d56597f59531': 'ADD_GIFT_MESSAGE_RIGHTS',
        'f13e6f9e-49f1-4632-893f-ec6ac6ebf1a9': 'ADD_PRODUCT_RIGHTS',
        'b7eec7c3-e21b-4493-8d8e-756832af80ec': 'ADD_SHIPPING_ADDRESS_RIGHTS',
        '7ed003fb-bc3e-4b4e-afe2-4710ed86fe78': 'ADD_SHIPPING_MODE_RIGHTS',
        '8e95f3ff-2f9f-426e-ac0c-e42cdeba3b5d': 'CREATE_A_CART_FROM_ORDER_RIGHTS',
        '43555ba1-57f3-4702-a8a8-8748583ac5c0': 'DELETE_GIFT_MESSAGE_RIGHTS',
        'f1b120fb-0198-46bd-8460-2a8473818f79': 'DELETE_PRODUCT_RIGHTS',
        'abb1eb6f-5482-495d-8c78-5743e5046e80': 'GET_AVAILABLE_SHIPPING_MODES_RIGHTS',
        '3397a958-5a50-4647-8057-9417e6024ab1': 'UPDATE_PRODUCT_QUANTITY_RIGHTS',
        'f9c6bbdf-627f-4553-ac91-8bb0844cd7cf': 'CREATE_CART_NEW_ORDER_RIGHTS',
        '96d6c035-0450-4342-b818-483b89b0e118': 'GET_ORDER_DETAILS_RIGHTS',
    },
    retail_cart: {
        '0298fcce-5de2-11ee-8559-731cc77f1c9c': 'ADD_BILLING_ADDRESS_RIGHTS',
        '0298fe90-5de2-11ee-855a-cbdb17fc3e19': 'ADD_ECARD_TO_CART_RIGHTS',
        '0298feea-5de2-11ee-855b-0b3341384f2d': 'ADD_GIFT_MESSAGE_TO_CART_RIGHTS',
        '219da398-e576-47a4-a901-6cec6bfbc6e8': 'ADD_CASH_PAYMENT_TO_CART_RIGHTS',
        '41ec104a-5de3-11ee-9eb5-2b6cee91cfbf': 'ADD_PRODUCT_TO_CART_RIGHTS',
        '41ec11bc-5de3-11ee-9eb6-b74fa70be8d3': 'SET_SHIPPING_ADDRESS_RIGHTS',
        '41ec11f8-5de3-11ee-9eb7-db5eb98f3253': 'SET_SHIPPING_MODE_RIGHTS',
        'bf12eed6-5de3-11ee-a813-33287665720b': 'APPLY_CREDIT_RIGHTS',
        'bf12efee-5de3-11ee-a814-2be963f10e30': 'APPLY_VOUCHER_CODE_RIGHTS',
        'bf12f020-5de3-11ee-a815-e3531d114cbf': 'APPLY_ECARD_RIGHTS',
        'd241d0de-150f-41f4-bc65-1de21c0e3468': 'ASSIGN_USER_RIGHTS',
        '9ddeb4aa-5def-11ee-9264-cb1e8baa0884': 'LIST_CASHIER_ACTIVE_CARTS_RIGHTS',
        '09516d18-5df0-11ee-9801-1798acf1e2da': 'CREATE_CART_RIGHTS',
        '2c3ee15d-77aa-427f-8c29-ffadf108f4dd': 'DELETE_CART_RIGHTS',
        'f8942d6f-86ab-49d9-a865-14fc173b2cba': 'DELETE_PRODUCT_RIGHTS',
        '172728a4-a855-4960-ac75-d8fbffe13aad': 'REMOVE_APPLIED_CREDIT_RIGHTS',
        'c6a0c602-70e4-4be9-9680-faf26a4a3d70': 'REMOVE_APPLIED_VOUCHER_CODE_RIGHTS',
        '1e36f038-f0b9-4f78-bebd-bd24464c8627': 'EXONERATE_VAT_RIGHTS',
        '0d9568d1-00de-4b5b-9564-1ef7d22ff53a': 'GET_CART_RIGHTS',
        '126226db-3a6d-4ec2-90b6-33ab7d47f7e4': 'GET_AVAILABLE_SHIPPING_MODES_RIGHTS',
        'cdb86ba3-9f9d-4ed6-9e7d-4747220e2451': 'MARK_AS_PAID_WITH_CASH_RIGHTS',
        'bbdf0c43-a65d-4f3c-9817-620b087a58b3': 'CANCEL_LINKED_ORDERS_RIGHTS',
        '301d0110-079a-41a6-9b3f-254d67444599': 'ADD_OR_UPDATE_CUSTOMISATION_RIGHTS',
        '5e833d8f-dc5a-473b-819e-fbcd915b53c7': 'ADD_OR_UPDATE_PRODUCT_RIGHTS',
    },
    admin: {
        'd0ed744a-a263-41eb-997e-cfd30354d42b': 'DEBUG_CART_EAN_COUNT_RIGHTS',
        'cea3c683-fb4b-4de2-8de6-99fafa15c5f2': 'LIST_SALESTAX_RIGHTS',
        '8c50a3d9-38d0-4854-855d-af14eb8197ec': 'UPDATE_SALESTAX_RIGHTS',
        '9ac33254-52c1-4ac3-9e1e-76afac0770df': 'UPDATE_VAT_IN_BATCH_RIGHTS',
        '93e66ad7-c4c5-42cd-9e6f-e1d4feff6231': 'LIST_VAT_RIGHTS',
        '4b3fb978-0af6-479f-9bf1-19cc6f7b79d8': 'UPDATE_VAT_RIGHTS',
    },
    commercial_operation: {
        'a2fc9fa2-d692-4ec6-a036-2f17364faf6d': 'CREATE_COMMERCIAL_OPE_RIGHTS',
        '43869084-b5d9-43d4-aa2d-425f12e063b6': 'DELETE_COMMERCIAL_OPE_RIGHTS',
        '74031115-4139-43ab-9369-be779d65f26b': 'GET_COMMERCIAL_OPE_RIGHTS',
        'd84782e2-ab04-4f7f-a3fa-4c391d50d0fa': 'LIST_COMMERCIAL_OPES_RIGHTS',
        '38b48f5a-645e-4727-b102-c3df4ea6884a': 'UPDATE_COMMERCIAL_OPE_RIGHTS',
    },
    voucher_campaign: {
        '3ce250f6-9469-4151-9269-5fe35c6cd483': 'GET_VOUCHER_CAMPAIGN_RIGHTS',
        'e27ea267-c86d-45f3-aedf-9dfd3acd8aa9': 'CREATE_VOUCHER_CAMPAIGN_CUSTOMER_RIGHTS',
        '9836adc7-326e-4d79-8c53-397c66bee517': 'CREATE_VOUCHER_CAMPAIGN_FRIENDS_AND_FAMILY_RIGHTS',
        '178a981f-01a8-49e0-8cde-d7685689c738': 'CREATE_VOUCHER_CAMPAIGN_INFLUENCE_RIGHTS',
        '40ec0c76-3c05-46cd-afb7-2605f4f4786f': 'CREATE_VOUCHER_CAMPAIGN_STAFF_RIGHTS',
        '605fc4a8-6e14-4e2b-af53-2c273f89c408': 'CREATE_VOUCHER_CAMPAIGN_RETAIL_RIGHTS',
        '3174e1f0-4b45-4934-926c-2cf86a0fdf52': 'DELETE_VOUCHER_CAMPAIGN_RIGHTS',
        'ae043186-8b5a-4bd8-bbde-b1b3f5e11785': 'EXPORT_ALLOWED_VARIANTS_RIGHTS',
        'b0078542-c0d3-4d82-89b9-5f8836e686fb': 'EXPORT_CODES_RIGHTS',
        '79e17c20-0a9b-4071-b91d-d1d409243828': 'EXPORT_EXCLUDED_VARIANTS_RIGHTS',
        '682a66bf-1911-4da6-8c28-ea12c3b13463': 'IMPORT_ALLOWED_VARIANTS_RIGHTS',
        'abcd5d5e-7296-43b9-ae9f-63b32710f6b2': 'IMPORT_EXCLUDED_VARIANTS_RIGHTS',
        '5c17b240-8418-4915-8b25-40c8bb4cc92b': 'IMPORT_ELIGIBLE_USERS_RIGHTS',
        '24997a4c-017a-435e-b45a-9eb08ca1f9a1': 'LIST_VOUCHER_CAMPAIGNS_RIGHTS',
        '4bb5bf61-1f91-41e5-a6e9-b036d73fed5a': 'UPDATE_VOUCHER_CAMPAIGN_CUSTOMER_RIGHTS',
        'e9325a60-e35a-4ced-a4d8-036f09274996': 'UPDATE_VOUCHER_CAMPAIGN_FRIENDS_AND_FAMILY_RIGHTS',
        '07c29545-ef78-4427-82b5-5a66670a1f9c': 'UPDATE_VOUCHER_CAMPAIGN_INFLUENCE_RIGHTS',
        '6882252a-1e89-452e-98de-f1e3438f77a4': 'UPDATE_VOUCHER_CAMPAIGN_STAFF_RIGHTS',
        '087def0c-5dd2-45ac-969f-3bc5b487ca98': 'UPDATE_VOUCHER_CAMPAIGN_RETAIL_RIGHTS',
        'cdb5d298-4290-4602-a06b-318e87960189': 'LIST_CODES_RIGHTS',
        'd3561db1-a343-4a07-9eb8-1cef41719fb6': 'UPDATE_CODE_RIGHTS',
        '491acf98-0a06-4474-95b9-c16ca4cc8913': 'ADD_CODE_TO_USER_RIGHTS',
    },
    cron: {
        '4121b94e-c2a1-4fce-acaa-c90f47f57b76': 'DELETE_CRON_RIGHTS',
        '0529e5ef-3906-4ad9-a07e-34f7f44ac057': 'GET_CRONS_RIGHTS',
        '170dcd0d-094e-47c5-8da3-d5dbec2aa0cb': 'UPDATE_CRON_RIGHTS',
    },
    export_order_customer_ecard: {
        '9ab34e3f-6752-4ce7-96fb-4f4c15e166b1': 'CREATE_EXPORT_RIGHTS',
    },
    parameter: {
        'e337c3b4-afea-4d98-9e83-a9a0f5fd7c66': 'GET_PARAMETER_RIGHTS',
        '15e7f7b6-26ae-4ea5-ad52-521a22caffdf': 'UPDATE_PARAMETER_RIGHTS',
    },
    reference: {
        '68b7bba1-996d-449c-896b-d2761ed3808d': 'GET_REFERENCES_RIGHTS',
    },
    user: {
        '0635d277-d3a2-4fff-a83c-cebf76dd17fd': 'GET_ADDRESSES_RIGHTS',
        'b3555bdc-4dc3-4338-a1a9-7ee7cc45ee39': 'UPDATE_ADDRESS_RIGHTS',
        '69124d0c-f98f-4cab-b8c6-61f8c8175bd9': 'ADD_COMMENT_RIGHTS',
        'e4c1c25c-162b-44a0-9fe9-b4565c21b84c': 'LIST_COMMENTS_RIGHTS',
        '9ccd24b2-613f-4b8a-8439-793e3cf86a52': 'DESACTIVATE_USER_RIGHTS',
        'ed1144aa-f80f-41a7-9e22-238b85e333de': 'SEARCH_USERS_RIGHTS',
        'f88b071c-d2b6-4784-b6e2-ad10f26c21ba': 'REACTIVATE_USER_RIGHTS',
        '92591c1f-1e9a-4aa0-a1f3-dc44df2e32a4': 'GET_USER_INFORMATIONS_RIGHTS',
        'efb7ee25-30ad-4e9d-a995-b546f12e7098': 'RESET_PASSWORD_RIGHTS',
        'f3e70b75-b8ac-4196-9889-3c66285ee245': 'UPDATE_USER_INFORMATIONS_RIGHTS',
    },
    credit: {
        '53096f75-777b-4aa0-883c-a63dc428f66e': 'CREATE_CREDIT_RIGHTS',
        '7f221fff-d0b9-4e4f-b6d0-84519a947f2c': 'GET_CREDIT_RIGHTS',
        '3fbd6f59-df5e-4fd6-aca7-895f342b951c': 'IMPORT_CREDITS_RIGHTS',
        '5b0eae05-e666-46ed-9533-53e710a887da': 'LIST_CREDITS_RIGHTS',
        '9db5b8b6-7faa-472b-b950-0e12117b5451': 'UPDATE_CREDIT_RIGHTS',
    },
    ecard: {
        'd53e110c-03cb-43d3-965c-df041360164b': 'SEARCH_ECARD_RIGHTS',
        '31e10738-2724-4aca-93fc-6140519ac4f1': 'UPDATE_ECARD_RIGHTS',
    },
    newsletter: {
        '8a944bb3-b00f-4eb6-89f2-40064e97a08e': 'IMPORT_SUBSCRIBERS_RIGHTS',
        '76ac7a0e-78c7-454c-a464-8bb12ef46441': 'LIST_SUBSCRIBERS_RIGHTS',
        '728c262f-bad8-4e0f-bf3a-869569e7caac': 'GET_SUBSCRIBER_RIGHTS',
        '39271abd-8cfb-4b9c-9682-02c9ddc9d659': 'UPDATE_SUBSCRIBER_RIGHTS',
        '31cf87c1-6959-46d4-ad2f-c5ea3c9303fa': 'UPDATE_SUBSCRIPTIONS_SUBSCRIBER_RIGHTS',
    },
    order: {
        '3acb342c-f13e-4c92-9305-5442cf88652a': 'CANCEL_ORDER_RIGHTS',
        '1cf09590-f88b-4143-b016-c564eb0e3685': 'GET_GIFT_MESSAGE_RIGHTS',
        'e990867e-1de5-4234-8345-c9f45262afe1': 'GET_HAS_RETURN_LABEL_RIGHTS',
        '5baa6c45-bba7-422b-8048-a369050f4cce': 'IMPORT_TRACKING_DETAILS_RIGHTS',
        '5136219e-666d-471a-900b-39fd4c49561a': 'CANCEL_MULTIPLE_ORDERS_RIGHTS',
        'f61dcdb6-b5ec-4509-b7c1-bc58dbb1560c': 'UPDATE_MULTIPLE_ORDERS_RIGHTS',
        '924ff617-18ee-4c8e-846f-7d64dd2a9c5f': 'GET_RETURN_LABEL_RIGHTS',
        '52c4e71a-5aaf-4435-8421-38dcf02edad0': 'LIST_TRANSITIONS_HISTORY_RIGHTS',
        '73b3794d-fdeb-46f8-aaa8-34cff5bd0056': 'EXOPORT_CUSTOMISATIONS_RIGHTS',
        '57f0965c-8b28-46d6-bbbb-3618a90f20ce': 'LIST_ORDERS_RIGHTS',
        '93a7bed7-788f-4969-a52b-95e59dc2c456': 'MERGE_ORDER_MANUALLY_RIGHTS',
        '1859d19f-b149-4445-bfdd-152100588a38': 'GET_DETAILS_RIGHTS',
        'ba3ee150-0c42-469e-9f96-5d4720cf38f6': 'UPDATE_DETAILS_RIGHTS',
        '2d8b5504-0edc-48d1-81ca-fc50d46c7b30': 'UPDATE_STATUS_RIGHTS',
        '68996924-50bb-4242-97df-b21f21501d71': 'LIST_PAYMENT_METHODS_USED_RIGHTS',
        '7380325b-0380-4e82-af21-a46df241a466': 'PREVENT_REFUNDING_RIGHTS',
        'c067bbd9-90d1-4a14-9572-3635177edac3': 'REAUTHORIZE_REFUNDING_RIGHTS',
        '93d3ef45-abe4-47e8-83c9-f0bc2d615145': 'RESEND_ECARD_RIGHTS',
        'f9533c86-d6f8-4e55-9aa2-1d5dadc1624c': 'GENERATE_INVOICE_RIGHTS',
        '0df89d8b-2e6a-4618-abc2-050865222ff2': 'GET_INVOICE_RIGHTS',
        '2e3606e5-c544-444a-907d-527ff68ff873': 'IMPORT_SHOP_SPOTS_RIGHTS',
        'a630a562-5bfa-40a1-8c56-b04ea173699e': 'UPDATE_TRACKING_RIGHTS',
    },
    alert: {
        '561820dc-383b-42e9-a0df-26eac5f51061': 'DELETE_STOCK_ALERT_RIGHTS',
        'e88eb389-1cac-4697-8c33-3c312240d3da': 'EXPORT_STOCK_ALERTS_RIGHTS',
        '0b729bcf-60c2-4420-a9f4-2f7cda657fd8': 'IMPORT_STOCK_ALERTS_RIGHTS',
        '62ec05d4-90c5-4e40-a7f3-7641dc778b54': 'LIST_STOCK_ALERTS_RIGHTS',
        'c0c9a915-63ab-49db-9361-55f2cc3f703e': 'DELETE_MULTIPLE_STOCK_ALERTS_RIGHTS',
        '17828c10-654a-4efd-816c-c7141438fe49': 'UPDATE_STOCK_ALERT_RIGHTS',
    },
    tag: {
        '7c45bb35-bc35-46df-ab51-88c4f12cbe19': 'CREATE_TAG_RIGHTS',
        '8800b940-f2ad-42b1-9764-dbbd883e1d10': 'DELETE_TAG_RIGHTS',
        'c01e131b-c526-4e79-bb73-200371660d23': 'IMPORT_TAG_RIGHTS',
        'd5ee17d2-5e88-460c-9e97-38ac8079b73c': 'LIST_TAGS_RIGHTS',
        '583f331e-da0a-4597-81fc-e30b7a5ffa82': 'UPDATE_TAG_RIGHTS',
    },
    return_from_bo: {
        '13528258-4afa-4f3f-80f6-b7e9180a8b9d': 'GET_INVOICE_RIGHTS',
        '8be061b9-5ca5-498b-a919-c5acc13a0c68': 'GET_AMOUNT_TO_REFUND_RIGHTS',
        '64c57280-1ece-4943-8ea4-e93ea55d53b3': 'IMPORT_RETURNS_FROM_CSV_RIGHTS',
        'fff79b59-8632-4a4c-907b-8cf8e369a05a': 'MAKE_AS_PROCESSED_RIGHTS',
        '73f74cdf-93c1-4e19-a0fd-f0e45a9b4afb': 'CANCEL_RETURN_RIGHTS',
        'd747efd6-0ec8-4ba7-9a38-d750e1722773': 'CREATE_RETURN_RIGHTS',
        'fa660484-c107-42d9-a02a-de19c332d15c': 'DELETE_RETURN_RIGHTS',
        '9cd53074-5470-4f48-82e4-26fa336559a4': 'GET_RETURN_DETAILS_RIGHTS',
        'e439fbf0-c021-4d19-8578-f353d1f496fe': 'UPDATE_REFUNDED_QUANTITY_RIGHTS',
        '1c593418-36c3-4a50-8a5b-e3ff33b56f65': 'GET_RETURN_LABEL_RIGHTS',
        '34e6b288-6c2d-49bd-ae54-56f269fa6db3': 'LIST_RETURNS_RIGHTS',
        '7382112c-d910-4121-b102-98d0672a6ec8': 'CREATE_RETURN_MODE_RIGHTS',
        '946807c7-c9cb-44d6-aa60-1e71505acec1': 'DELETE_RETURN_MODE_RIGHTS',
        '33dfc862-ef9e-4913-95a9-ab1b811261ad': 'GET_RETURN_MODE_DETAILS_RIGHTS',
        'eee29611-9ee6-4d1a-ad59-5e83c95f3da2': 'LIST_RETURN_MODES_RIGHTS',
        'c7b325b6-0571-4cb5-b59e-e193347aba5f': 'UPDATE_RETURN_MODE_RIGHTS',
        '18ce9c77-1cd8-4f9b-b31d-25036a2b10f9': 'UPDATE_RETURN_RIGHTS',
        'fdab0f3a-e70d-49b0-ac80-149aefb2ee10': 'PROCESS_RETURN_RIGHTS',
    },
    warehouse: {
        'ebbc6d9b-2624-45ab-b689-09e287407b76': 'CREATE_WAREHOUSE_RIGHTS',
        '09faf588-2fab-4281-8c3a-2fd917a6743f': 'DELETE_WAREHOUSE_RIGHTS',
        '3823dcdf-f695-4a62-b83d-c8a6cb84caf0': 'GET_WAREHOUSE_DETAILS_RIGHTS',
        'b5a61cc7-44a4-4aba-8b52-8b53fb6772fe': 'LIST_WAREHOUSES_RIGHTS',
        '83ac1f9b-60ef-45f9-abf2-d8719001b24b': 'UPDATE_WAREHOUSE_RIGHTS',
        'e559b097-cd91-4ea2-9fc6-432bff2c0814': 'LIST_WAREHOUSE_OVERLOAD_SHIPPING_MODE_RIGHTS',
        'fb6d5956-20a9-42ca-a873-9fad3a4c41cf': 'LIST_LOGISTIC_COUNTRY_SETTINGS_RIGHTS',
        'fb1b0fe2-3e5f-4dc8-8252-ae6404da6a62': 'UPDATE_LOGISTIC_COUNTRY_SETTINGS_RIGHTS',
    },
    retail_order: {
        'c3c06440-c3ff-413f-bdcd-8928eb667185': 'CANCEL_LAST_MINUTE_ORDER_RIGHTS',
        '20354e7e-bd11-4820-8db2-a5e5e454bdd1': 'GET_RETURN_LABEL_RIGHTS',
        'e70cdc92-8fce-40f6-9b3a-6385caa62d56': 'LIST_RETURNS_RIGHTS',
    },
    return_from_retail: {
        '27c87e1a-214f-4034-a12d-a9e0d7c0ff10': 'COMPUTE_REFUND_AMOUNT_RIGHTS',
        '1573beb1-f0d9-4dae-ac3d-73fe4e5e8f5d': 'CANCER_RETURN_RIGHTS',
        'd536cef8-f43b-4cc5-af65-e50f5c171b07': 'CREATE_RETURN_RIGHTS',
        '007a6c7a-ee6f-40ce-8d80-20136705402a': 'GET_RETURN_DETAILS_RIGHTS',
        '73b39bad-3299-446e-8112-33bd8a114561': 'UPDATE_RETURN_RIGHTS',
        '1a04388a-f33e-43be-8921-e8bc9e64a0e8': 'PROCESS_RETURN_MANUALLY_RIGHTS',
    },
    debug: {
        '50addd0e-e9ef-4769-bbfc-b0ba86763a27': 'COUNT_AND_COMPARE_EAN_IN_ORDERS_WITH_STOCK_RIGHTS',
    },
    refund: {
        '66ba6e26-5704-4269-be01-0022430111ff': 'APPLY_COMMERCIAL_REFUND_RIGHTS',
        'b34e87ae-38e0-4281-8731-798593231d8d': 'GET_COMMERCIAL_REFUND_RIGHTS',
        '8d52a280-2783-49a9-8526-083057eb35db': 'APPLY_MANUAL_REFUND_RIGHTS',
        'f0f3ad3e-6f2e-4f97-85c7-b8dca92d32ce': 'IMPORT_COMPLAINTS_FROM_CSV_RIGHTS',
        '6ae9dd7b-9778-4b57-bc30-75a584217c0f': 'CANCEL_REFUND_RIGHTS',
    },
    packaging: {
        'd033916d-35f0-4340-b88f-2e36108c7f8d': 'CREATE_PACKAGING_RIGHTS',
        '71e5248f-351b-4c9f-b707-854345d8d0c4': 'DELETE_PACKAGING_RIGHTS',
        '0d2b4c75-f3eb-43eb-ad3b-7b94e55bc9af': 'EXPORT_PACKAGING_DETAILS_RIGHTS',
        'f6a75dba-c4fb-4c63-ad19-661d56b93500': 'GET_PACKAGING_DETAILS_RIGHTS',
        '383b7b54-7b96-41fe-985d-0286d0d5aae7': 'IMPORT_PACKAGING_DETAILS_RIGHTS',
        '1e1c316f-145a-4cc5-9ae8-bd5e1973f250': 'LIST_PACKAGINGS_RIGHTS',
        'a098c12d-e7e5-4719-ab91-5bc4fb560d78': 'DEPRECATED_GET_PRODUCT_LINKED_TO_PACKAGING_RIGHTS',
        '72659c76-d100-43da-90e3-0ec1c47b9995': 'DEPRECATED_UPDATE_PRODUCT_LINKED_TO_PACKAGING_RIGHTS',
        '356c7755-6aec-4426-be59-9129a805e642': 'UPDATE_PACKAGING_RIGHTS',
    },
    email_template: {
        '35b22574-7b83-4a60-9a6d-6398251ef8d0': 'CREATE_EMAIL_TEMPLATE_VERSION_RIGHTS',
        '83513a80-387b-445b-be89-51eddd37c984': 'DELETE_EMAIL_TEMPLATE_VERSION_RIGHTS',
        'db539c5b-fe3e-4d0e-b03f-39e3403a03e3': 'LIST_EMAIL_TEMPLATES_RIGHTS',
        'e0ce2fed-a204-4293-8c5b-c17bcb49ac99': 'LIST_EMAIL_TEMPLATES_VERSION_RIGHTS',
        'ff3fb253-94c4-4448-aa08-f6dc0a99ffa1': 'UPDATE_EMAIL_TEMPLATE_VERSION_RIGHTS',
    },
    shipping_mode: {
        '660cc82c-77e9-49d1-a2fb-0180fa458410': 'LIST_SHIPPING_MODES_BY_MISSED_CURRENCY_RIGHTS',
        'f3a5a292-0642-4541-9ec0-4658697d8d87': 'CREATE_SHIPPING_MODE_RIGHTS',
        'ab7b7987-0332-4cb5-8111-0ff21b92d45a': 'DELETE_SHIPPING_MODE_RIGHTS',
        '687f92e6-4b41-4b8a-884e-fdc2dd5f0bf3': 'GET_SHIPPING_MODE_DETAILS_RIGHTS',
        '6dfcfd21-e258-4248-9bbd-c4072568a768': 'LIST_SHIPPING_MODES_RIGHTS',
        '1c1fc128-01fc-431a-bf11-67e60693f083': 'GET_POUCH_SHIPPING_PARAMS_RIGHTS',
        'd2f10280-7480-4e65-a981-9f731571cc3f': 'UPDATE_POUCH_SHIPPING_PARAMS_RIGHTS',
        'b515cda0-44a0-4187-8a11-112e2f335440': 'UPDATE_SHIPPING_MODE_RIGHTS',
    },
    return_mode: {
        '8412a588-eb69-4dc0-ad8c-63b9ef6bd252': 'LIST_RETURN_MODE_BY_SHIPPING_MODE_RIGHTS',
        'd1473a25-56b5-456a-95d7-7f903f0740d6': 'UPDATE_RETURN_MODES_BY_SHIPPING_MODE_RIGHTS',
    },
    shop: {
        '44f57cb6-8870-4d1a-8d61-cd7858dd2166': 'CREATE_SHOP_RIGHTS',
        '689fe502-5b3c-4422-8f96-b2416a3c56c0': 'DELETE_SHOP_RIGHTS',
        '72023bb2-6a2e-487f-8ad1-0fed2b2799e2': 'GET_SHOP_DETAILS_RIGHTS',
        '690875f6-402f-43dd-ae14-be1da160c07e': 'LIST_SHOPS_RIGHTS',
        'ae3deefe-0abb-4076-847a-2feee1dc0138': 'UPDATE_SHOP_RIGHTS',
    },
} as const;
