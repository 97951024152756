import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { CopyToClipboard } from 'sezane-components';

import TableDropdown from '@components/table/Dropdown';
import { formatLink } from '@config/formatters';
import routesNames from '@config/routesNames';
import { getBucketQuantity, ReferencesWarehouse } from '@containers/scheduledUpdates/utils';
import { OMS, Stock } from '@types';

export const setSortableFields = (warehouses: OMS.References['warehouses']) => {
    const warehouseFields = warehouses?.reduce((acc, warehouse) => {
        const isExcluded = warehouse.name === 'US1';

        return {
            ...acc,
            [`stock_diff_warehouse_purchased_${warehouse.name}`]: `${Stock.BucketType.ProjectedInWarehouseMinusPurchased}_${warehouse.uid}`,
            [`stock_web_purchased_${warehouse.name}`]: `${Stock.BucketType.WebSold}_${warehouse.uid}`,
            [`stock_real_${warehouse.name}`]: `${Stock.BucketType.RealInWarehouse}_${warehouse.uid}`,
            [`stock_web_to_integrate_${warehouse.name}`]: `${Stock.BucketType.WebToIntegrate}_${warehouse.uid}`,
            [`stock_diff_${warehouse.name}`]: `${Stock.BucketType.ProjectedInWarehouseDiff}_${warehouse.uid}`,
            [`stock_temp_${warehouse.name}`]: `${Stock.BucketType.TempInWarehouse}_${warehouse.uid}`,
            [`stock_rack_${warehouse.name}`]: `${Stock.BucketType.RackInWarehouse}_${warehouse.uid}`,
            ...(!isExcluded && {
                [`stock_web_front_${warehouse.name}`]: `${Stock.BucketType.WebFront}_${warehouse.uid}`,
                [`stock_cart_${warehouse.name}`]: `${Stock.BucketType.WebCart}_${warehouse.uid}`,
            }),
            ...(isExcluded && {
                [`stock_real_retail_return_${warehouse.name}`]: `${Stock.BucketType.RealInWarehouseRetailReturn}_${warehouse.uid}`,
                [`stock_real_customer_return_${warehouse.name}`]: `${Stock.BucketType.RealInWarehouseCustomerReturn}_${warehouse.uid}`,
                [`stock_real_retail_supply_${warehouse.name}`]: `${Stock.BucketType.RealInWarehouseRetailSupply}_${warehouse.uid}`,
            }),
        };
    }, {});

    return {
        // add exceptions for US warehouse
        ...warehouseFields,
    };
};

export const DEFAULT_FIELDS = {
    product: true,
    product_code: true,
    ean: true,
    // FR columns
    update_mode_FR1: true,
    stock_diff_warehouse_purchased_FR1: true,
    stock_web_front_FR1: true,
    stock_diff_FR1: true,
    stock_temp_FR1: true,
    stock_rack_FR1: true,
    stock_web_purchased_FR1: true,
    stock_web_to_integrate_FR1: false,
    stock_real_FR1: true,
    stock_cart_FR1: false,
    // US columns
    stock_diff_warehouse_purchased_US1: false,
    stock_diff_US1: false,
    stock_temp_US1: false,
    stock_rack_US1: false,
    stock_web_purchased_US1: false,
    stock_real_US1: false,
    stock_web_to_integrate_US1: false,
    stock_real_retail_return_US1: false,
    stock_real_customer_return_US1: false,
    stock_real_retail_supply_US1: false,
    // DE columns
    update_mode_DE1: false,
    stock_diff_warehouse_purchased_DE1: false,
    stock_diff_DE1: false,
    stock_temp_DE1: false,
    stock_rack_DE1: false,
    stock_web_purchased_DE1: false,
    stock_web_front_DE1: false,
    stock_web_to_integrate_DE1: false,
    stock_real_DE1: false,
    stock_cart_DE1: false,
};

const bucketFieldOptions = {
    className: 'u-fz(sm)',
};

export const fieldOptions = (warehouses: OMS.References['warehouses']) => {
    const warehousesFieldOptions = warehouses?.reduce((acc, warehouse) => {
        const isExcluded = warehouse.name === 'US1';

        return {
            ...acc,
            [`stock_diff_warehouse_purchased_${warehouse.name}`]: bucketFieldOptions,
            [`stock_real_${warehouse.name}`]: bucketFieldOptions,
            [`stock_diff_${warehouse.name}`]: {
                ...bucketFieldOptions,
                helper: <FormattedMessage id="stock_view.fields.stock_diff.helper" />,
            },
            [`stock_web_purchased_${warehouse.name}`]: {
                ...bucketFieldOptions,
                helper: <FormattedMessage id="stock_view.fields.stock_web_purchased.helper" />,
            },
            [`stock_web_to_integrate_${warehouse.name}`]: {
                ...bucketFieldOptions,
                helper: <FormattedMessage id="stock_view.fields.stock_web_to_integrate.helper" />,
            },
            // Not in US warehouse
            ...(!isExcluded && {
                [`stock_web_front_${warehouse.name}`]: {
                    ...bucketFieldOptions,
                },
                [`update_${warehouse.name}`]: bucketFieldOptions,
                [`stock_cart_${warehouse.name}`]: bucketFieldOptions,
            }),
            // Buckets for US warehouse
            ...(isExcluded && {
                [`stock_real_retail_return_${warehouse.name}`]: bucketFieldOptions,
                [`stock_real_customer_return_${warehouse.name}`]: bucketFieldOptions,
                [`stock_real_retail_supply_${warehouse.name}`]: bucketFieldOptions,
            }),
        };
    }, {} as Record<string, any>);

    return {
        product: {
            align: 'left',
            width: '200p',
            className: 'u-pl(xs) u-mw(300p)',
        },
        product_code: {
            className: 'u-fz(sm)',
            width: '150p',
        },
        ean: {
            className: 'u-fz(sm)',
            width: '150p',
        },
        ...warehousesFieldOptions,
        // Actions
        actions: {
            hideLabel: true,
            fillCell: true,
            width: '60p',
        },
    };
};

export const formatProduct = ({ model_name, color, size }: Stock.EanStocks) => (
    <>
        {model_name}
        <div className="u-fz(sm) u-fw(lighter)">
            {color} - {size}
        </div>
    </>
);

export const formatProductCode = ({ product_code }: Stock.EanStocks) => (
    <CopyToClipboard value={product_code || ''}>
        <pre>{formatLink(product_code, `${routesNames.LIST_PRODUCTS}?search=${product_code}`)}</pre>
    </CopyToClipboard>
);

export const formatEan = ({ ean }: Stock.EanStocks) => (
    <CopyToClipboard value={ean}>
        <pre>{formatLink(ean, `${routesNames.LIST_PRODUCTS}?search=${ean}`)}</pre>
    </CopyToClipboard>
);

export const formatUpdateMode =
    (warehouse: ReferencesWarehouse) =>
    ({ update_modes }: Stock.EanStocks) => {
        const updateMode = update_modes?.find(({ warehouse_uuid }) => warehouse_uuid === warehouse.uid);
        return updateMode ? (
            <FormattedMessage id={`scheduled_updates.update_mode.${updateMode.update_mode.toLowerCase()}`} />
        ) : null;
    };

export const formatStockQuantity =
    (type: Stock.BucketType, warehouse: ReferencesWarehouse) =>
    ({ stocks }: Stock.EanStocks) => {
        const quantity = getBucketQuantity(stocks, type, warehouse);
        return quantity !== undefined ? quantity : '-';
    };

export default (
    openEditModal: (editedEan: Stock.EanStocks) => void,
    warehouses: ReferencesWarehouse[]
): Record<string, (eanStock: Stock.EanStocks) => ReactNode> => {
    const warehouseFields = warehouses.reduce((acc, warehouse) => {
        const isExcluded = warehouse.name === 'US1';
        return {
            ...acc,
            ...(!isExcluded && {
                // Not in US warehouse
                [`update_mode_${warehouse.name}`]: formatUpdateMode(warehouse),
                [`stock_web_front_${warehouse.name}`]: formatStockQuantity(Stock.BucketType.WebFront, warehouse),
            }),
            [`stock_diff_warehouse_purchased_${warehouse.name}`]: formatStockQuantity(
                Stock.BucketType.ProjectedInWarehouseMinusPurchased,
                warehouse
            ),
            [`stock_web_purchased_${warehouse.name}`]: formatStockQuantity(Stock.BucketType.WebPurchased, warehouse),
            [`stock_diff_${warehouse.name}`]: formatStockQuantity(Stock.BucketType.ProjectedInWarehouseDiff, warehouse),
            [`stock_temp_${warehouse.name}`]: formatStockQuantity(Stock.BucketType.TempInWarehouse, warehouse),
            [`stock_rack_${warehouse.name}`]: formatStockQuantity(Stock.BucketType.RackInWarehouse, warehouse),
            ...(!isExcluded && {
                // Not in US warehouse
                [`stock_cart_${warehouse.name}`]: formatStockQuantity(Stock.BucketType.WebCart, warehouse),
            }),
            [`stock_real_${warehouse.name}`]: formatStockQuantity(Stock.BucketType.RealInWarehouse, warehouse),
            // US warehouse
            ...(isExcluded && {
                [`stock_real_retail_return_${warehouse.name}`]: formatStockQuantity(
                    Stock.BucketType.RealInWarehouseRetailReturn,
                    warehouse
                ),
                [`stock_real_customer_return_${warehouse.name}`]: formatStockQuantity(
                    Stock.BucketType.RealInWarehouseCustomerReturn,
                    warehouse
                ),
                [`stock_real_retail_supply_${warehouse.name}`]: formatStockQuantity(
                    Stock.BucketType.RealInWarehouseRetailSupply,
                    warehouse
                ),
            }),
            [`stock_web_to_integrate_${warehouse.name}`]: formatStockQuantity(
                Stock.BucketType.WebToIntegrate,
                warehouse
            ),
        };
    }, {} as any);

    return {
        product: formatProduct,
        product_code: formatProductCode,
        ean: formatEan,
        ...warehouseFields,

        // Actions
        actions: (eanStock: Stock.EanStocks) => (
            <TableDropdown
                options={[
                    {
                        renderOption: () => <FormattedMessage key="edit" id="common.edit" />,
                        onSelect: () => openEditModal(eanStock),
                    },
                ]}
            />
        ),
    };
};
