import routesNames from '@config/routesNames';
import { RootState } from '@reducers';
import { matchPath } from 'react-router';

// Access rights as defined in project/apps/api/config/packages/security.yaml
export enum ACCESS_RIGHTS {
    // Catalog
    ROLE_DASHBOARD_READ = 'ROLE_DASHBOARD_READ',

    // Catalog
    ROLE_SALES_READ = 'ROLE_SALES_READ',
    ROLE_SALES_WRITE = 'ROLE_SALES_WRITE',
    ROLE_PRODUCTS_READ = 'ROLE_PRODUCTS_READ',
    ROLE_PRODUCTS_WRITE = 'ROLE_PRODUCTS_WRITE',
    ROLE_MULTI_PRODUCTS_READ = 'ROLE_MULTI_PRODUCTS_READ',
    ROLE_MULTI_PRODUCTS_WRITE = 'ROLE_MULTI_PRODUCTS_WRITE',
    ROLE_PREORDERS_READ = 'ROLE_PREORDERS_READ',
    ROLE_PREORDERS_WRITE = 'ROLE_PREORDERS_WRITE',
    ROLE_CUSTOMISATION_READ = 'ROLE_CUSTOMISATION_READ',
    ROLE_CUSTOMISATION_WRITE = 'ROLE_CUSTOMISATION_WRITE',
    ROLE_SEQUENCING_READ = 'ROLE_SEQUENCING_READ',
    ROLE_SEQUENCING_WRITE = 'ROLE_SEQUENCING_WRITE',

    // Contents
    ROLE_PAGES_READ = 'ROLE_PAGES_READ',
    ROLE_PAGES_WRITE = 'ROLE_PAGES_WRITE',
    ROLE_MEDIAS_READ = 'ROLE_MEDIAS_READ',
    ROLE_MEDIAS_WRITE = 'ROLE_MEDIAS_WRITE',
    ROLE_FAQ_READ = 'ROLE_FAQ_READ',
    ROLE_FAQ_WRITE = 'ROLE_FAQ_WRITE',
    ROLE_POPINS_READ = 'ROLE_POPINS_READ',
    ROLE_POPINS_WRITE = 'ROLE_POPINS_WRITE',
    ROLE_PERSISTENT_CONTENT_READ = 'ROLE_PERSISTENT_CONTENT_READ',
    ROLE_PERSISTENT_CONTENT_WRITE = 'ROLE_PERSISTENT_CONTENT_WRITE',
    ROLE_MOBILE_CONTENTS_READ = 'ROLE_MOBILE_CONTENTS_READ',
    ROLE_MOBILE_CONTENTS_WRITE = 'ROLE_MOBILE_CONTENTS_WRITE',
    ROLE_EVERGREEN_MEDIAS_READ = 'ROLE_EVERGREEN_MEDIAS_READ',
    ROLE_EVERGREEN_MEDIAS_WRITE = 'ROLE_EVERGREEN_MEDIAS_WRITE',

    // Navigation
    ROLE_MENU_READ = 'ROLE_MENU_READ',
    ROLE_MENU_WRITE = 'ROLE_MENU_WRITE',
    ROLE_FOOTER_READ = 'ROLE_FOOTER_READ',
    ROLE_FOOTER_WRITE = 'ROLE_FOOTER_WRITE',
    ROLE_MANDATORY_LOGIN_READ = 'ROLE_MANDATORY_LOGIN_READ',
    ROLE_MANDATORY_LOGIN_WRITE = 'ROLE_MANDATORY_LOGIN_WRITE',

    // Workspaces
    ROLE_WORKSPACES_READ = 'ROLE_WORKSPACES_READ',
    ROLE_WORKSPACES_WRITE = 'ROLE_WORKSPACES_WRITE',

    // Orders
    ROLE_ORDERS_READ = 'ROLE_ORDERS_READ',
    ROLE_ORDERS_WRITE = 'ROLE_ORDERS_WRITE',
    ROLE_CANCEL_ORDER = 'ROLE_CANCEL_ORDER',
    ROLE_REORDER = 'ROLE_REORDER',
    ROLE_CARTS_READ = 'ROLE_CARTS_READ',
    ROLE_USERS_READ = 'ROLE_USERS_READ',
    ROLE_USERS_WRITE = 'ROLE_USERS_WRITE',
    ROLE_USER_TAGS_READ = 'ROLE_USER_TAGS_READ',
    ROLE_USER_TAGS_WRITE = 'ROLE_USER_TAGS_WRITE',
    ROLE_NEWSLETTER_SUBSCRIBERS_READ = 'ROLE_NEWSLETTER_SUBSCRIBERS_READ',
    ROLE_NEWSLETTER_SUBSCRIBERS_WRITE = 'ROLE_NEWSLETTER_SUBSCRIBERS_WRITE',
    ROLE_USER_CREDITS_READ = 'ROLE_USER_CREDITS_READ',
    ROLE_USER_CREDITS_WRITE = 'ROLE_USER_CREDITS_WRITE',
    ROLE_ECARDS_READ = 'ROLE_ECARDS_READ',
    ROLE_ECARDS_WRITE = 'ROLE_ECARDS_WRITE',
    ROLE_ORDER_RETURN_QUANTITY_WRITE = 'ROLE_ORDER_RETURN_QUANTITY_WRITE',

    // Stock
    ROLE_STOCK_VIEW_READ = 'ROLE_STOCK_VIEW_READ',
    ROLE_STOCK_VIEW_WRITE = 'ROLE_STOCK_VIEW_WRITE',
    ROLE_STOCK_VIEW_SEARCH_READ = 'ROLE_STOCK_VIEW_SEARCH_READ',
    ROLE_STOCK_VIEW_SEARCH_WRITE = 'ROLE_STOCK_VIEW_SEARCH_WRITE',
    ROLE_SCHEDULED_UPDATES_READ = 'ROLE_SCHEDULED_UPDATES_READ',
    ROLE_SCHEDULED_UPDATES_WRITE = 'ROLE_SCHEDULED_UPDATES_WRITE',
    ROLE_STOCK_SHOP_VIEW_READ = 'ROLE_STOCK_SHOP_VIEW_READ',

    // Retail
    ROLE_RETAIL_SALES_READ = 'ROLE_RETAIL_SALES_READ',
    ROLE_RETAIL_SALES_WRITE = 'ROLE_RETAIL_SALES_WRITE',

    // Ope com
    ROLE_PROMOTIONAL_CAMPAIGN_READ = 'ROLE_PROMOTIONAL_CAMPAIGN_READ',
    ROLE_PROMOTIONAL_CAMPAIGN_WRITE = 'ROLE_PROMOTIONAL_CAMPAIGN_WRITE',
    ROLE_VOUCHER_CAMPAIGNS_READ = 'ROLE_VOUCHER_CAMPAIGNS_READ',
    ROLE_VOUCHER_CAMPAIGNS_WRITE = 'ROLE_VOUCHER_CAMPAIGNS_WRITE',
    ROLE_PUSH_PRODUCTS_READ = 'ROLE_PUSH_PRODUCTS_READ',
    ROLE_PUSH_PRODUCTS_WRITE = 'ROLE_PUSH_PRODUCTS_WRITE',
    ROLE_PACKAGINGS_READ = 'ROLE_PACKAGINGS_READ',
    ROLE_PACKAGINGS_WRITE = 'ROLE_PACKAGINGS_WRITE',
    ROLE_SALES_STATISTICS_READ = 'ROLE_SALES_STATISTICS_READ',

    // Parameters
    ROLE_REPORTS_READ = 'ROLE_REPORTS_READ',
    ROLE_REPORTS_WRITE = 'ROLE_REPORTS_WRITE',
    ROLE_COLORS_READ = 'ROLE_COLORS_READ',
    ROLE_COLORS_WRITE = 'ROLE_COLORS_WRITE',
    ROLE_SHIPPING_MODE_READ = 'ROLE_SHIPPING_MODE_READ',
    ROLE_SHIPPING_MODE_WRITE = 'ROLE_SHIPPING_MODE_WRITE',
    ROLE_SHOPS_READ = 'ROLE_SHOPS_READ',
    ROLE_SHOPS_WRITE = 'ROLE_SHOPS_WRITE',
    ROLE_SALES_TAX_READ = 'ROLE_SALES_TAX_READ',
    ROLE_SALES_TAX_WRITE = 'ROLE_SALES_TAX_WRITE',
    ROLE_VAT_READ = 'ROLE_VAT_READ',
    ROLE_VAT_WRITE = 'ROLE_VAT_WRITE',
    ROLE_EMAIL_TEMPLATES_READ = 'ROLE_EMAIL_TEMPLATES_READ',
    ROLE_EMAIL_TEMPLATES_WRITE = 'ROLE_EMAIL_TEMPLATES_WRITE',
    ROLE_LOGISTIC_FILES_READ = 'ROLE_LOGISTIC_FILES_READ',

    // Admin
    ROLE_SITES_READ = 'ROLE_SITES_READ',
    ROLE_SITES_WRITE = 'ROLE_SITES_WRITE',
    ROLE_PARAMETERS_READ = 'ROLE_PARAMETERS_READ',
    ROLE_PARAMETERS_WRITE = 'ROLE_PARAMETERS_WRITE',
    ROLE_CRONS_WRITE = 'ROLE_CRONS_WRITE',
    ROLE_QUEUE_WRITE = 'ROLE_QUEUE_WRITE',
    ROLE_LEGACY_WRITE = 'ROLE_LEGACY_WRITE',
    ROLE_ROLES_READ = 'ROLE_ROLES_READ',
    ROLE_ROLES_WRITE = 'ROLE_ROLES_WRITE',
}

export const ROUTES_ACCESS_RIGHTS: Partial<Record<keyof typeof routesNames, ACCESS_RIGHTS>> = {
    [routesNames.DASHBOARD]: ACCESS_RIGHTS.ROLE_DASHBOARD_READ,
    // Catalog
    [routesNames.LIST_SALES]: ACCESS_RIGHTS.ROLE_SALES_READ,
    [routesNames.UPDATE_SALE]: ACCESS_RIGHTS.ROLE_SALES_READ, // TODO Check write role in the container
    [routesNames.CREATE_SALE]: ACCESS_RIGHTS.ROLE_SALES_WRITE,
    [routesNames.LIST_PRODUCTS]: ACCESS_RIGHTS.ROLE_PRODUCTS_READ,
    [routesNames.UPDATE_PRODUCT]: ACCESS_RIGHTS.ROLE_PRODUCTS_READ, // TODO Check write role in the container
    [routesNames.LIST_MULTI_PRODUCTS]: ACCESS_RIGHTS.ROLE_MULTI_PRODUCTS_READ,
    [routesNames.UPDATE_MULTI_PRODUCT]: ACCESS_RIGHTS.ROLE_MULTI_PRODUCTS_READ, // TODO Check write role in the container
    [routesNames.CREATE_MULTI_PRODUCT]: ACCESS_RIGHTS.ROLE_MULTI_PRODUCTS_WRITE,
    [routesNames.LIST_PREORDERS]: ACCESS_RIGHTS.ROLE_PREORDERS_READ,
    [routesNames.LIST_CUSTOMISATION_SERVICES]: ACCESS_RIGHTS.ROLE_CUSTOMISATION_READ,
    [routesNames.UPDATE_CUSTOMISATION_SERVICES]: ACCESS_RIGHTS.ROLE_CUSTOMISATION_READ,
    [routesNames.CREATE_CUSTOMISATION_SERVICES]: ACCESS_RIGHTS.ROLE_CUSTOMISATION_WRITE,
    [routesNames.LIST_SEQUENCINGS]: ACCESS_RIGHTS.ROLE_SEQUENCING_READ,
    [routesNames.SHOW_SEQUENCING]: ACCESS_RIGHTS.ROLE_SEQUENCING_READ,
    // Content
    [routesNames.LIST_PAGES]: ACCESS_RIGHTS.ROLE_PAGES_READ,
    [routesNames.UPDATE_PAGE]: ACCESS_RIGHTS.ROLE_PAGES_READ, // TODO Check write role in the container
    [routesNames.MEDIA_LIBRARY]: ACCESS_RIGHTS.ROLE_MEDIAS_READ,
    [routesNames.LIST_POPINS]: ACCESS_RIGHTS.ROLE_POPINS_READ,
    [routesNames.UPDATE_POPIN]: ACCESS_RIGHTS.ROLE_POPINS_READ,
    [routesNames.CREATE_POPIN]: ACCESS_RIGHTS.ROLE_POPINS_WRITE,
    [routesNames.LIST_MOBILE_CONTENTS]: ACCESS_RIGHTS.ROLE_MOBILE_CONTENTS_READ,
    [routesNames.LIST_EVERGREEN_MEDIAS]: ACCESS_RIGHTS.ROLE_EVERGREEN_MEDIAS_READ,
    [routesNames.LIST_PUSH_PRODUCTS]: ACCESS_RIGHTS.ROLE_PUSH_PRODUCTS_READ,
    [routesNames.UPDATE_PUSH_PRODUCT]: ACCESS_RIGHTS.ROLE_PUSH_PRODUCTS_WRITE,
    [routesNames.CREATE_PUSH_PRODUCT]: ACCESS_RIGHTS.ROLE_PUSH_PRODUCTS_WRITE,
    [routesNames.LIST_WORKSPACES]: ACCESS_RIGHTS.ROLE_WORKSPACES_READ,
    [routesNames.LIST_COMPOSITION_LAYOUTS]: ACCESS_RIGHTS.ROLE_PERSISTENT_CONTENT_READ,
    [routesNames.UPDATE_COMPOSITION_LAYOUT]: ACCESS_RIGHTS.ROLE_PERSISTENT_CONTENT_READ,
    [routesNames.CREATE_COMPOSITION_LAYOUT]: ACCESS_RIGHTS.ROLE_PERSISTENT_CONTENT_WRITE,
    // Navigation
    [routesNames.MAIN_MENU]: ACCESS_RIGHTS.ROLE_MENU_READ,
    [routesNames.FOOTER]: ACCESS_RIGHTS.ROLE_FOOTER_READ,
    [routesNames.PRE_FOOTER]: ACCESS_RIGHTS.ROLE_FOOTER_READ,
    [routesNames.UPDATE_MANDATORY_LOGIN]: ACCESS_RIGHTS.ROLE_MANDATORY_LOGIN_READ,
    // Orders
    [routesNames.LIST_CARTS]: ACCESS_RIGHTS.ROLE_CARTS_READ,
    [routesNames.MASS_ORDERS]: ACCESS_RIGHTS.ROLE_ORDERS_WRITE,
    [routesNames.LIST_ORDERS]: ACCESS_RIGHTS.ROLE_ORDERS_READ,
    [routesNames.SHOW_ORDER]: ACCESS_RIGHTS.ROLE_ORDERS_READ,
    [routesNames.LIST_USER_CREDITS]: ACCESS_RIGHTS.ROLE_USER_CREDITS_READ,
    [routesNames.LIST_COMMERCIAL_OPERATIONS]: ACCESS_RIGHTS.ROLE_PROMOTIONAL_CAMPAIGN_READ,
    [routesNames.EDIT_COMMERCIAL_OPERATION]: ACCESS_RIGHTS.ROLE_PROMOTIONAL_CAMPAIGN_WRITE,
    [routesNames.CREATE_COMMERCIAL_OPERATION]: ACCESS_RIGHTS.ROLE_PROMOTIONAL_CAMPAIGN_WRITE,
    [routesNames.LIST_VOUCHER_CAMPAIGNS]: ACCESS_RIGHTS.ROLE_VOUCHER_CAMPAIGNS_READ,
    [routesNames.SHOW_VOUCHER_CAMPAIGN]: ACCESS_RIGHTS.ROLE_VOUCHER_CAMPAIGNS_READ,
    [routesNames.UPDATE_VOUCHER_CAMPAIGN]: ACCESS_RIGHTS.ROLE_VOUCHER_CAMPAIGNS_READ,
    [routesNames.CREATE_VOUCHER_CAMPAIGN]: ACCESS_RIGHTS.ROLE_VOUCHER_CAMPAIGNS_WRITE,
    [routesNames.LIST_ECARDS]: ACCESS_RIGHTS.ROLE_ECARDS_READ,
    [routesNames.LIST_SALES_STATISTICS]: ACCESS_RIGHTS.ROLE_SALES_STATISTICS_READ,
    // Clients
    [routesNames.LIST_USERS]: ACCESS_RIGHTS.ROLE_USERS_READ,
    [routesNames.SHOW_USER]: ACCESS_RIGHTS.ROLE_USERS_READ,
    // Stock
    [routesNames.STOCK_VIEW_SEARCH]: ACCESS_RIGHTS.ROLE_STOCK_VIEW_SEARCH_READ,
    [routesNames.STOCK_VIEW]: ACCESS_RIGHTS.ROLE_STOCK_VIEW_READ,
    [routesNames.LIST_SCHEDULED_UPDATES]: ACCESS_RIGHTS.ROLE_SCHEDULED_UPDATES_READ,
    [routesNames.EDIT_SCHEDULED_UPDATE]: ACCESS_RIGHTS.ROLE_SCHEDULED_UPDATES_READ,
    [routesNames.CREATE_SCHEDULED_UPDATE]: ACCESS_RIGHTS.ROLE_SCHEDULED_UPDATES_READ,
    [routesNames.STOCK_SHOP_VIEW]: ACCESS_RIGHTS.ROLE_STOCK_SHOP_VIEW_READ,
    // Retail
    [routesNames.LIST_SHOPS]: ACCESS_RIGHTS.ROLE_SHOPS_READ,
    [routesNames.UPDATE_SHOPS]: ACCESS_RIGHTS.ROLE_SHOPS_WRITE,
    [routesNames.CREATE_SHOPS]: ACCESS_RIGHTS.ROLE_SHOPS_WRITE,
    // Logistic
    [routesNames.LIST_PACKAGINGS]: ACCESS_RIGHTS.ROLE_PACKAGINGS_READ,
    [routesNames.CREATE_PACKAGING]: ACCESS_RIGHTS.ROLE_PACKAGINGS_WRITE,
    [routesNames.LIST_SHIPPING_MODES]: ACCESS_RIGHTS.ROLE_SHIPPING_MODE_READ,
    [routesNames.UPDATE_SHIPPING_MODE]: ACCESS_RIGHTS.ROLE_SHIPPING_MODE_READ,
    [routesNames.CREATE_SHIPPING_MODE]: ACCESS_RIGHTS.ROLE_SHIPPING_MODE_WRITE,
    [routesNames.LOGISTIC_FILES]: ACCESS_RIGHTS.ROLE_LOGISTIC_FILES_READ,
    // Admin
    [routesNames.LIST_LOG_REPORTS]: ACCESS_RIGHTS.ROLE_REPORTS_READ,
    [routesNames.LIST_ROLES]: ACCESS_RIGHTS.ROLE_ROLES_READ,
    [routesNames.LIST_SITES]: ACCESS_RIGHTS.ROLE_SITES_READ,
    [routesNames.UPDATE_SITE]: ACCESS_RIGHTS.ROLE_SITES_READ,
    [routesNames.CREATE_SITE]: ACCESS_RIGHTS.ROLE_SITES_WRITE,
    [routesNames.CREATE_BRAND]: ACCESS_RIGHTS.ROLE_SITES_WRITE,
    [routesNames.LIST_PARAMETERS]: ACCESS_RIGHTS.ROLE_PARAMETERS_READ,
    [routesNames.LIST_USER_TAGS]: ACCESS_RIGHTS.ROLE_USER_TAGS_READ,
    [routesNames.LIST_COLOR_PARAMETERS]: ACCESS_RIGHTS.ROLE_COLORS_READ,
    [routesNames.LIST_VAT]: ACCESS_RIGHTS.ROLE_VAT_READ,
    [routesNames.LIST_SALES_TAX]: ACCESS_RIGHTS.ROLE_SALES_TAX_READ,
    [routesNames.LIST_EMAIL_TEMPLATES]: ACCESS_RIGHTS.ROLE_EMAIL_TEMPLATES_READ,
    [routesNames.UPDATE_EMAIL_TEMPLATE]: ACCESS_RIGHTS.ROLE_EMAIL_TEMPLATES_WRITE,
};

export type RoleRessource = {
    name: string;
    read?: ACCESS_RIGHTS;
    write?: ACCESS_RIGHTS;
};

export const ROLE_RESSOURCE_MAPPING: Record<string, RoleRessource[]> = {
    'sidebar.home': [
        {
            name: 'sidebar.home',
            read: ACCESS_RIGHTS.ROLE_DASHBOARD_READ,
        },
    ],
    'sidebar.catalog': [
        {
            name: 'sidebar.sales',
            read: ACCESS_RIGHTS.ROLE_SALES_READ,
            write: ACCESS_RIGHTS.ROLE_SALES_WRITE,
        },
        {
            name: 'sidebar.products',
            read: ACCESS_RIGHTS.ROLE_PRODUCTS_READ,
            write: ACCESS_RIGHTS.ROLE_PRODUCTS_WRITE,
        },
        {
            name: 'sidebar.preorders',
            read: ACCESS_RIGHTS.ROLE_PREORDERS_READ,
            write: ACCESS_RIGHTS.ROLE_PREORDERS_WRITE,
        },
        {
            name: 'sidebar.customisation_services',
            read: ACCESS_RIGHTS.ROLE_CUSTOMISATION_READ,
            write: ACCESS_RIGHTS.ROLE_CUSTOMISATION_WRITE,
        },
        {
            name: 'sidebar.sequencings',
            read: ACCESS_RIGHTS.ROLE_SEQUENCING_READ,
            write: ACCESS_RIGHTS.ROLE_SEQUENCING_WRITE,
        },
    ],
    'sidebar.content': [
        {
            name: 'sidebar.page_versions',
            read: ACCESS_RIGHTS.ROLE_PAGES_READ,
            write: ACCESS_RIGHTS.ROLE_PAGES_WRITE,
        },
        {
            name: 'sidebar.media_library',
            read: ACCESS_RIGHTS.ROLE_MEDIAS_READ,
            write: ACCESS_RIGHTS.ROLE_MEDIAS_WRITE,
        },
        {
            name: 'sidebar.popins',
            read: ACCESS_RIGHTS.ROLE_POPINS_READ,
            write: ACCESS_RIGHTS.ROLE_POPINS_WRITE,
        },
        {
            name: 'sidebar.mobile_content',
            read: ACCESS_RIGHTS.ROLE_MOBILE_CONTENTS_READ,
            write: ACCESS_RIGHTS.ROLE_MOBILE_CONTENTS_WRITE,
        },
        {
            name: 'sidebar.evergreen_medias',
            read: ACCESS_RIGHTS.ROLE_EVERGREEN_MEDIAS_READ,
            write: ACCESS_RIGHTS.ROLE_EVERGREEN_MEDIAS_WRITE,
        },
        {
            name: 'sidebar.push_products',
            read: ACCESS_RIGHTS.ROLE_PUSH_PRODUCTS_READ,
            write: ACCESS_RIGHTS.ROLE_PUSH_PRODUCTS_WRITE,
        },
        {
            name: 'sidebar.workspaces',
            read: ACCESS_RIGHTS.ROLE_WORKSPACES_READ,
            write: ACCESS_RIGHTS.ROLE_WORKSPACES_WRITE,
        },
    ],
    'sidebar.navigation': [
        {
            name: 'sidebar.menu',
            read: ACCESS_RIGHTS.ROLE_MENU_READ,
            write: ACCESS_RIGHTS.ROLE_MENU_WRITE,
        },
        {
            name: 'sidebar.footer',
            read: ACCESS_RIGHTS.ROLE_FOOTER_READ,
            write: ACCESS_RIGHTS.ROLE_FOOTER_WRITE,
        },
        {
            name: 'sidebar.mandatory_login',
            read: ACCESS_RIGHTS.ROLE_MANDATORY_LOGIN_READ,
            write: ACCESS_RIGHTS.ROLE_MANDATORY_LOGIN_WRITE,
        },
    ],
    'sidebar.orders': [
        {
            name: 'sidebar.carts',
            read: ACCESS_RIGHTS.ROLE_CARTS_READ,
        },
        {
            name: 'sidebar.orders',
            read: ACCESS_RIGHTS.ROLE_ORDERS_READ,
            write: ACCESS_RIGHTS.ROLE_ORDERS_WRITE,
        },
        {
            name: 'access_rights.cancel_order',
            write: ACCESS_RIGHTS.ROLE_CANCEL_ORDER,
        },
        {
            name: 'access_rights.reorder',
            write: ACCESS_RIGHTS.ROLE_REORDER,
        },
        {
            name: 'roles.name.order_return_quantity',
            write: ACCESS_RIGHTS.ROLE_ORDER_RETURN_QUANTITY_WRITE,
        },
        {
            name: 'sidebar.user_credit',
            read: ACCESS_RIGHTS.ROLE_USER_CREDITS_READ,
            write: ACCESS_RIGHTS.ROLE_USER_CREDITS_WRITE,
        },
        {
            name: 'sidebar.commercial_operations',
            read: ACCESS_RIGHTS.ROLE_PROMOTIONAL_CAMPAIGN_READ,
            write: ACCESS_RIGHTS.ROLE_PROMOTIONAL_CAMPAIGN_WRITE,
        },
        {
            name: 'sidebar.voucher_campaigns',
            read: ACCESS_RIGHTS.ROLE_VOUCHER_CAMPAIGNS_READ,
            write: ACCESS_RIGHTS.ROLE_VOUCHER_CAMPAIGNS_WRITE,
        },
        {
            name: 'sidebar.ecards',
            read: ACCESS_RIGHTS.ROLE_ECARDS_READ,
            write: ACCESS_RIGHTS.ROLE_ECARDS_WRITE,
        },
        {
            name: 'sidebar.sales_statistics',
            read: ACCESS_RIGHTS.ROLE_SALES_STATISTICS_READ,
        },
    ],
    'sidebar.clients': [
        {
            name: 'sidebar.clients',
            read: ACCESS_RIGHTS.ROLE_USERS_READ,
            write: ACCESS_RIGHTS.ROLE_USERS_WRITE,
        },
        {
            name: 'sidebar.newsletter_subscribers',
            read: ACCESS_RIGHTS.ROLE_NEWSLETTER_SUBSCRIBERS_READ,
            write: ACCESS_RIGHTS.ROLE_NEWSLETTER_SUBSCRIBERS_WRITE,
        },
    ],
    'sidebar.stock': [
        {
            name: 'sidebar.stock_view_search',
            read: ACCESS_RIGHTS.ROLE_STOCK_VIEW_SEARCH_READ,
            write: ACCESS_RIGHTS.ROLE_STOCK_VIEW_SEARCH_WRITE,
        },
        {
            name: 'sidebar.stock_view',
            read: ACCESS_RIGHTS.ROLE_STOCK_VIEW_READ,
            write: ACCESS_RIGHTS.ROLE_STOCK_VIEW_WRITE,
        },
        {
            name: 'sidebar.scheduled_updates',
            read: ACCESS_RIGHTS.ROLE_SCHEDULED_UPDATES_READ,
            write: ACCESS_RIGHTS.ROLE_SCHEDULED_UPDATES_WRITE,
        },
        {
            name: 'sidebar.stock_shop_search',
            read: ACCESS_RIGHTS.ROLE_STOCK_SHOP_VIEW_READ,
        },
    ],
    'sidebar.retail': [
        {
            name: 'sidebar.retail_sales',
            read: ACCESS_RIGHTS.ROLE_RETAIL_SALES_READ,
            write: ACCESS_RIGHTS.ROLE_RETAIL_SALES_WRITE,
        },
        {
            name: 'sidebar.shops',
            read: ACCESS_RIGHTS.ROLE_SHOPS_READ,
            write: ACCESS_RIGHTS.ROLE_SHOPS_WRITE,
        },
    ],
    'sidebar.logistic': [
        {
            name: 'sidebar.packagings',
            read: ACCESS_RIGHTS.ROLE_PACKAGINGS_READ,
            write: ACCESS_RIGHTS.ROLE_PACKAGINGS_WRITE,
        },
        {
            name: 'roles.name.shipping_mode',
            read: ACCESS_RIGHTS.ROLE_SHIPPING_MODE_READ,
            write: ACCESS_RIGHTS.ROLE_SHIPPING_MODE_WRITE,
        },
        {
            name: 'sidebar.logistic_files',
            read: ACCESS_RIGHTS.ROLE_LOGISTIC_FILES_READ,
        },
    ],
    'sidebar.administration': [
        {
            name: 'sidebar.log_reports',
            read: ACCESS_RIGHTS.ROLE_REPORTS_READ,
            write: ACCESS_RIGHTS.ROLE_REPORTS_WRITE,
        },
        {
            name: 'sidebar.roles',
            read: ACCESS_RIGHTS.ROLE_ROLES_READ,
            write: ACCESS_RIGHTS.ROLE_ROLES_WRITE,
        },
        {
            name: 'sidebar.sites',
            read: ACCESS_RIGHTS.ROLE_SITES_READ,
            write: ACCESS_RIGHTS.ROLE_SITES_WRITE,
        },
        {
            name: 'sidebar.constants',
            read: ACCESS_RIGHTS.ROLE_PARAMETERS_READ,
            write: ACCESS_RIGHTS.ROLE_PARAMETERS_WRITE,
        },
        {
            name: 'sidebar.crons',
            write: ACCESS_RIGHTS.ROLE_CRONS_WRITE,
        },
        {
            name: 'sidebar.user_tags',
            read: ACCESS_RIGHTS.ROLE_USER_TAGS_READ,
            write: ACCESS_RIGHTS.ROLE_USER_TAGS_WRITE,
        },
        {
            name: 'sidebar.color_parameters',
            read: ACCESS_RIGHTS.ROLE_COLORS_READ,
            write: ACCESS_RIGHTS.ROLE_COLORS_WRITE,
        },
        {
            name: 'sidebar.vat',
            read: ACCESS_RIGHTS.ROLE_VAT_READ,
            write: ACCESS_RIGHTS.ROLE_VAT_WRITE,
        },
        {
            name: 'sidebar.sales_tax',
            read: ACCESS_RIGHTS.ROLE_SALES_TAX_READ,
            write: ACCESS_RIGHTS.ROLE_SALES_TAX_WRITE,
        },
        {
            name: 'sidebar.email_templates',
            read: ACCESS_RIGHTS.ROLE_EMAIL_TEMPLATES_READ,
            write: ACCESS_RIGHTS.ROLE_EMAIL_TEMPLATES_WRITE,
        },
    ],
};

export const getAccessRightFromPath = (path: string): ACCESS_RIGHTS | false => {
    const routes = Object.values(routesNames).sort((a, b) => (a.length > b.length ? -1 : 1));
    const matchRoute = matchPath(path, { path: routes });
    if (!matchRoute) return false;

    const accessRight = ROUTES_ACCESS_RIGHTS[matchRoute.path as keyof typeof ROUTES_ACCESS_RIGHTS];
    return accessRight ? accessRight : false;
};

export const accessRightsSelector = (state: RootState) => {
    return state.auth.accessRights;
};

export const hasAccessRight = (accessRights: ACCESS_RIGHTS[], accessRight: ACCESS_RIGHTS): boolean => {
    if (!Object.values(ACCESS_RIGHTS).includes(accessRight)) {
        console.error(`Invalid access right "${accessRight}", you probably need to update acl.ts`);
    }

    return accessRights.includes(accessRight);
};
