import { OMS, Stock } from '@types';

export type ReferencesWarehouse = NonNullable<OMS.References['warehouses']>[0];

export const getBucketQuantity = (buckets: Stock.Bucket[], type: Stock.BucketType, warehouse: ReferencesWarehouse) => {
    const stock = buckets.find(
        bucket => bucket.bucket_type === type && (!warehouse || bucket.warehouse_uuid === warehouse.uid)
    );
    return stock?.quantity;
};

const datePad = (value: number) => value.toString().padStart(2, '0');

export const formatDateForExport = (date: Date) => {
    const day = datePad(date.getDate());
    const month = datePad(date.getMonth() + 1);
    const year = date.getFullYear();
    const hours = datePad(date.getHours());
    const minutes = datePad(date.getMinutes());
    return `${year}_${month}_${day}_${hours}_${minutes}`;
};
