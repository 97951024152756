import { OMS } from '@types';
import { createActionCreator, withNoSite } from '../../crud';
import { name, schema, baseUrl } from './config';

const { types, actionCreator: actionCreatorCustomer } = createActionCreator(name, schema, `${baseUrl}/clients`);
const { actionCreator: actionCreatorEmployees } = createActionCreator(name, schema, `${baseUrl}/employees`);
const { actionCreator: actionCreatorInfluence } = createActionCreator(name, schema, `${baseUrl}/influence`);
const { actionCreator: actionCreatorFriendsAndFamily } = createActionCreator(name, schema, `${baseUrl}/friends_family`);
const { actionCreator: actionCreatorRetail } = createActionCreator(name, schema, `${baseUrl}/retail`);

export const TYPES = types;

export default (data: OMS.VoucherCampaign) => {
    const actionCreatorMap = {
        clients: actionCreatorCustomer,
        employees: actionCreatorEmployees,
        influence: actionCreatorInfluence,
        friends_family: actionCreatorFriendsAndFamily,
        retail: actionCreatorRetail,
    };

    const recipientType = data.recipientType;
    const actionCreator = actionCreatorMap[recipientType];

    if (!actionCreator) {
        throw new Error(`Invalid recipient type: ${recipientType}`);
    }

    return withNoSite(actionCreator(data));
};
