import routesNames from '@config/routesNames';
import { Paths, ReturnedMappedRights } from '@utils/hooks/useOmsRights';

type RightsPaths = Paths<ReturnedMappedRights>;
type RoutesPaths = typeof routesNames[keyof typeof routesNames];

export const ROUTES_ACCESS_RIGHTS_OMS: Partial<Record<RoutesPaths, RightsPaths>> = {
    // Returns
    [routesNames.LIST_RETURNS]: 'return_from_bo.LIST_RETURNS_RIGHTS',
    [routesNames.SHOW_RETURN]: 'return_from_bo.GET_RETURN_DETAILS_RIGHTS',
    //   Order Return Modes
    [routesNames.LIST_ORDER_RETURN_MODES]: 'return_from_bo.LIST_RETURN_MODES_RIGHTS',
    [routesNames.UPDATE_ORDER_RETURN_MODES]: 'return_from_bo.GET_RETURN_MODE_DETAILS_RIGHTS',
    [routesNames.CREATE_ORDER_RETURN_MODES]: 'return_from_bo.CREATE_RETURN_MODE_RIGHTS',
    //    CRON
    [routesNames.LIST_CRONS]: 'cron.GET_CRONS_RIGHTS',
    [routesNames.LIST_NEWSLETTER_SUBSCRIBERS]: 'newsletter.LIST_SUBSCRIBERS_RIGHTS',
    [routesNames.LIST_STOCK_ALERTS]: 'alert.LIST_STOCK_ALERTS_RIGHTS',
    //    Warehouse
    [routesNames.LIST_WAREHOUSES]: 'warehouse.LIST_WAREHOUSES_RIGHTS',
    [routesNames.UPDATE_WAREHOUSE]: 'warehouse.GET_WAREHOUSE_DETAILS_RIGHTS',
    [routesNames.CREATE_WAREHOUSE]: 'warehouse.CREATE_WAREHOUSE_RIGHTS',
    [routesNames.LOGISTIC_COUNTRY_SETTINGS]: 'warehouse.LIST_LOGISTIC_COUNTRY_SETTINGS_RIGHTS',
};
