import { produce } from 'immer';
import { schema } from '@actions/pageVersions/config';
import { TYPES as GET_TYPES } from '@actions/pageVersions/get';
import { TYPES as LIST_TYPES } from '@actions/pageVersions/list';
import { TYPES as UPDATE_TYPES } from '@actions/pageVersions/update';
import { TYPES as PATCH_TYPES } from '@actions/pageVersions/patch';
import { TYPES as DUPLICATE_TYPES } from '@actions/pageVersions/duplicate';
import { TYPES as PUBLISH_TYPES } from '@actions/pageVersions/publish';
import { TYPES as MARK_AS_HOMEPAGE_TYPES } from '@actions/pageVersions/markAsHomepage';
import { TYPES as CREATE_DRAFT_TYPES } from '@actions/pageVersions/createDraft';
import { TYPES as REMOVE_DRAFT_TYPES } from '@actions/pageVersions/removeDraft';
import { TYPES as CREATE_BLOCK_TYPES } from '@actions/blocks/create';
import { TYPES as UPDATE_BLOCK_TYPES } from '@actions/blocks/update';
import { TYPES as DELETE_BLOCK_TYPES } from '@actions/blocks/delete';
import { TYPES as BATCH_UPDATE_BLOCK_TYPES } from '@actions/blocks/batchUpdate';
import { TYPES as CREATE_COMPOSITION_TYPES } from '@actions/compositions/create';
import { TYPES as UPDATE_COMPOSITIONS_TYPES } from '@actions/compositions/update';
import { TYPES as DELETE_COMPOSITION_TYPES } from '@actions/compositions/delete';
import { TYPES as BATCH_UPDATE_COMPOSITION_TYPES } from '@actions/compositions/batchUpdate';
import { CRUD_INITIAL_STATE, getReducer, listReducer, patchReducer, reduceReducers, updateReducer } from './crud';

export const INITIAL_STATE = CRUD_INITIAL_STATE;

const duplicateReducer = (state, action) => {
    switch (action.type) {
        case DUPLICATE_TYPES.DUPLICATE: {
            return { ...state, loading: true };
        }
        case DUPLICATE_TYPES.DUPLICATE_FAILURE:
        case DUPLICATE_TYPES.DUPLICATE_SUCCESS: {
            return { ...state, loading: false };
        }

        default:
            return state;
    }
};

const markAsHomepageReducer = (state, action) => {
    switch (action.type) {
        case MARK_AS_HOMEPAGE_TYPES.UPDATE: {
            return { ...state, loading: true };
        }
        case MARK_AS_HOMEPAGE_TYPES.UPDATE_FAILURE:
        case MARK_AS_HOMEPAGE_TYPES.UPDATE_SUCCESS: {
            return { ...state, loading: false };
        }

        default:
            return state;
    }
};

const createCompositionReducer = (state, action) => {
    switch (action.type) {
        case CREATE_COMPOSITION_TYPES.CREATE_SUCCESS: {
            const context = action.meta.previousAction.context;
            const composition = action.payload.rawData;

            return produce(state, draftState => {
                draftState.entities[context.pageVersionId].draft.compositions.push(composition);

                return draftState;
            });
        }

        default:
            return state;
    }
};

const updateCompositionReducer = (state, action) => {
    switch (action.type) {
        // Optimistic update for react-beautiful-dnd
        case UPDATE_COMPOSITIONS_TYPES.UPDATE: {
            const context = action.context;
            const data = action.payload.request.data;

            return produce(state, draftState => {
                const compositions = draftState.entities[context.pageVersionId].draft.compositions;
                const compositionId = compositions.findIndex(composition => composition.id === data.id);

                if (data.blockOrder) {
                    compositions[compositionId].blockOrder = data.blockOrder;
                }

                if (data.blocks) {
                    data.blocks.forEach(block => {
                        compositions[compositionId].blocks.forEach(compositionBlock => {
                            if (compositionBlock.id === block.id) {
                                compositionBlock.position = block.position;
                            }
                        });
                    });
                }

                return draftState;
            });
        }

        case UPDATE_COMPOSITIONS_TYPES.UPDATE_SUCCESS: {
            const context = action.meta.previousAction.context;
            const updatedComposition = action.payload.rawData;

            return produce(state, draftState => {
                const compositions = draftState.entities[context.pageVersionId].draft.compositions;
                const compositionId = compositions.findIndex(composition => composition.id === updatedComposition.id);
                // Compositions are only updated for some specific properties
                compositions[compositionId].margin = updatedComposition.margin;
                compositions[compositionId].blockOrder = updatedComposition.blockOrder;
                compositions[compositionId].titlePositionInner = updatedComposition.titlePositionInner;
                compositions[compositionId].borderColorEnabled = updatedComposition.borderColorEnabled;
                compositions[compositionId].borderColorOverride = updatedComposition.borderColorOverride;
                compositions[compositionId].borderColor = updatedComposition.borderColor;
                compositions[compositionId].backgroundColorOverride = updatedComposition.backgroundColorOverride;
                compositions[compositionId].backgroundColor = updatedComposition.backgroundColor;
                compositions[compositionId].sliderArrowsColorOverride = updatedComposition.sliderArrowsColorOverride;
                compositions[compositionId].sliderArrowsColor = updatedComposition.sliderArrowsColor;

                return draftState;
            });
        }

        default:
            return state;
    }
};

const batchUpdateCompositionsReducer = (state, action) => {
    switch (action.type) {
        // Optimistic update for react-beautiful-dnd
        case BATCH_UPDATE_COMPOSITION_TYPES.BATCH_UPDATE: {
            const context = action.context;
            const data = action.payload.request.data;

            return produce(state, draftState => {
                const pageDraft = draftState.entities[context.pageVersionId].draft;
                data.forEach(updatedComposition => {
                    const composition = pageDraft.compositions.find(
                        composition => composition.id === updatedComposition.id
                    );
                    composition.position = updatedComposition.position;
                });
                pageDraft.compositions = pageDraft.compositions.sort((comp1, comp2) => comp1.position - comp2.position);
                return draftState;
            });
        }

        default:
            return state;
    }
};

const deleteCompositionReducer = (state, action) => {
    switch (action.type) {
        case DELETE_COMPOSITION_TYPES.DELETE_SUCCESS: {
            const context = action.meta.previousAction.context;
            const compositionId = action.meta.previousAction.id;

            return produce(state, draftState => {
                const draftPageVersion = draftState.entities[context.pageVersionId].draft;
                draftPageVersion.compositions = draftPageVersion.compositions.filter(
                    composition => composition.id !== compositionId
                );

                return draftState;
            });
        }

        default:
            return state;
    }
};

const createBlockReducer = (state, action) => {
    switch (action.type) {
        case CREATE_BLOCK_TYPES.CREATE_SUCCESS: {
            const context = action.meta.previousAction.context;
            const block = action.payload.rawData;

            return produce(state, draftState => {
                const composition = draftState.entities[context.pageVersionId].draft.compositions.find(
                    composition => composition.id === block.composition.id
                );
                composition.blocks.push(block);

                return draftState;
            });
        }

        default:
            return state;
    }
};

const updateBlockReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_BLOCK_TYPES.UPDATE_SUCCESS: {
            const context = action.meta.previousAction.context;
            const updatedBlock = action.payload.rawData;

            return produce(state, draftState => {
                const composition = draftState.entities[context.pageVersionId].draft.compositions.find(
                    composition => composition.id === updatedBlock.composition.id
                );
                const blockIndex = composition.blocks.findIndex(block => updatedBlock.id === block.id);
                composition.blocks[blockIndex] = updatedBlock;

                return draftState;
            });
        }

        default:
            return state;
    }
};

const deleteBlockReducer = (state, action) => {
    switch (action.type) {
        case DELETE_BLOCK_TYPES.DELETE_SUCCESS: {
            const context = action.meta.previousAction.context;
            const blockId = action.meta.previousAction.id;

            return produce(state, draftState => {
                const composition = draftState.entities[context.pageVersionId].draft.compositions.find(composition =>
                    composition.blocks.find(block => block.id === blockId)
                );
                composition.blocks = composition.blocks.filter(block => block.id !== blockId);

                return draftState;
            });
        }

        default:
            return state;
    }
};

const batchUpdateBlocksReducer = (state, action) => {
    switch (action.type) {
        case BATCH_UPDATE_BLOCK_TYPES.BATCH_UPDATE_SUCCESS: {
            const context = action.meta.previousAction.context;
            const updatedBlocks = action.payload.rawData;

            return produce(state, draftState => {
                updatedBlocks.forEach(updatedBlock => {
                    // Remove block from all compositions
                    const compositionRemoveBlock = draftState.entities[context.pageVersionId].draft.compositions.find(
                        composition => composition.blocks.find(block => block.id === updatedBlock.id)
                    );
                    compositionRemoveBlock.blocks = compositionRemoveBlock.blocks.filter(
                        block => block.id !== updatedBlock.id
                    );

                    // Add it to the target composition
                    const compositionToUpdate = draftState.entities[context.pageVersionId].draft.compositions.find(
                        composition => composition.id === updatedBlock.composition.id
                    );
                    compositionToUpdate.blocks.push(updatedBlock);
                });
                return draftState;
            });
        }

        default:
            return state;
    }
};

export default reduceReducers(
    getReducer(GET_TYPES, schema),
    listReducer(LIST_TYPES, schema),
    updateReducer(UPDATE_TYPES, schema),
    updateReducer(PUBLISH_TYPES, schema, true),
    updateReducer(CREATE_DRAFT_TYPES, schema),
    updateReducer(REMOVE_DRAFT_TYPES, schema),
    patchReducer(PATCH_TYPES, schema, true),
    duplicateReducer,
    markAsHomepageReducer,
    createCompositionReducer,
    updateCompositionReducer,
    batchUpdateCompositionsReducer,
    deleteCompositionReducer,
    createBlockReducer,
    updateBlockReducer,
    deleteBlockReducer,
    batchUpdateBlocksReducer
);
