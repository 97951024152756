/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * EAN13 code
 * @maxLength 13
 * @pattern ^\d{1,13}$
 */
export type Ean = string;

/**
 * List of EANs
 * @uniqueItems true
 */
export type EanCollection = Ean[];

/** Request body that defines a list of EANs */
export interface EanCollectionBody {
    /** List of EANs */
    eans: EanCollection;
}

/** Request body that defines a list of EANs, optionally in a list of shops. */
export interface EanCollectionInShopsBody {
    /** List of EANs */
    eans: EanCollection;
    /** @uniqueItems true */
    shop_uuids?: ShopUUID[];
}

/** Total quantity of an EAN or a list of EANs */
export interface TotalQuantity {
    /** @min 0 */
    total_quantity?: number;
}

/** Total quantities of an EAN or a list of EANs */
export interface TotalQuantities {
    /** @min 0 */
    ShopAvailable?: number;
    /** @min 0 */
    ShopReturned?: number;
}

/** Brand code */
export enum Brand {
    Sezane = 'sezane',
    Octobre = 'octobre',
}

/**
 * Shop UUID
 * @format uuid
 */
export type ShopUUID = string;

/**
 * Warehouse UUID
 * @format uuid
 */
export type WarehouseUUID = string;

/**
 * Service UUID
 * @format uuid
 */
export type ServiceUUID = string;

export interface EanDetails {
    /** EAN13 code */
    ean: Ean;
    product_code: string;
    previous_product_code: string;
    model_name: string;
    color: string;
    size: string;
    image: string;
    price_eur: string;
}

/** @uniqueItems true */
export type EanDetailsCollection = EanDetails[];

export interface EanStocks {
    /** EAN13 code */
    ean: Ean;
    /** @uniqueItems true */
    stocks: Bucket[];
    update_mode?: string;
    /** @uniqueItems true */
    update_modes?: WarehouseUpdateMode[];
    product_code?: string;
    previous_product_code?: string;
    model_name?: string;
    color?: string;
    size?: string;
    image?: string;
    /** Price in euros, divided by 100 */
    price_eur?: string;
    /** Price in the selected currency, not divided by 100 */
    price?: string;
}

export interface WarehouseUpdateMode {
    warehouse_uuid: string;
    update_mode: string;
}

export interface Bucket {
    bucket_type: BucketType;
    warehouse_uuid?: string;
    shop_uuid?: string;
    quantity: number;
}

export enum BucketType {
    WebAvailable = 'WebAvailable',
    WebCart = 'WebCart',
    WebPurchased = 'WebPurchased',
    WebToIntegrate = 'WebToIntegrate',
    ShopAvailable = 'ShopAvailable',
    ShopCart = 'ShopCart',
    ShopPurchased = 'ShopPurchased',
    ShopLmsCart = 'ShopLmsCart',
    ShopLmsPurchased = 'ShopLmsPurchased',
    ShopService = 'ShopService',
    ShopReturned = 'ShopReturned',
    ShopDefectReturned = 'ShopDefectReturned',
    RetailToSend = 'RetailToSend',
    RetailTotalToSend = 'RetailTotalToSend',
    RetailIncomingTransit = 'RetailIncomingTransit',
    RetailShopTransfer = 'RetailShopTransfer',
    ProjectedInWarehouse = 'ProjectedInWarehouse',
    RealInWarehouse = 'RealInWarehouse',
    RackInWarehouse = 'RackInWarehouse',
    TempInWarehouse = 'TempInWarehouse',
    ProjectedInWarehouseMinusPurchased = 'ProjectedInWarehouseMinusPurchased',
    WebFront = 'WebFront',
    ProjectedInWarehouseDiff = 'ProjectedInWarehouseDiff',
    WebSold = 'WebSold',
    RealInWarehouseRetailReturn = 'RealInWarehouseRetailReturn',
    RealInWarehouseCustomerReturn = 'RealInWarehouseCustomerReturn',
    RealInWarehouseRetailSupply = 'RealInWarehouseRetailSupply',
    RepatriationRetailReturn = 'RepatriationRetailReturn',
    RepatriationCustomerReturn = 'RepatriationCustomerReturn',
    RepatriationRetailSupply = 'RepatriationRetailSupply',
}

/** @uniqueItems true */
export type EanStocksCollection = EanStocks[];

export interface SuccessfulOperation {
    success: boolean;
}

export interface SuccessfulOperationWithWarehouse {
    success: boolean;
    warehouse_uuid: string;
}

export interface PartiallySuccessfulOperation {
    success: boolean;
    failure: {
        /** List of EANs */
        eans: EanCollection;
    };
}

export interface QuantityAwarePartiallySuccessfulOperation {
    success: boolean;
    failure: {
        eans: EanQuantityCollection;
    };
}

export interface AcceptedOperation {
    success: boolean;
}

export interface SuccessfulOperationEntityCreated {
    success: boolean;
    id: number;
}

export interface SuccessfulOperationScheduledUpdateCreated {
    success: boolean;
    scheduled_update: ScheduledUpdate;
}

/** Representing Problem Details in HTTP APIs */
export interface Rfc7807 {
    type: string;
    title: string;
    status: number;
    detail: string;
    'invalid-params': InvalidParams;
    /** @uniqueItems true */
    'batch-invalid-params':
        | {
              identifier: string;
              'invalid-params': InvalidParams;
          }[]
        | null;
    errors?: EanQuantityErrors;
}

/** @uniqueItems true */
export type EanQuantityErrors = {
    code: string;
    /** EAN13 code */
    ean: Ean;
    quantity: number;
    /** Warehouse UUID */
    warehouse_uuid?: WarehouseUUID;
    /** Shop UUID */
    shop_uuid?: ShopUUID;
    bucket: {
        type: string;
        /** Shop UUID */
        shop?: ShopUUID;
    };
}[];

/** @uniqueItems true */
export type InvalidParams =
    | {
          name: string;
          reason: string;
      }[]
    | null;

export interface EanQuantity {
    /** EAN13 code */
    ean: Ean;
    /** @min 0 */
    quantity: number;
    /** @format uuid */
    warehouse_uuid?: string | null;
    /** @format uuid */
    shop_uuid?: string | null;
}

/** @uniqueItems true */
export type EanQuantityCollection = EanQuantity[];

export interface EanQuantityWithTotal {
    /** EAN13 code */
    ean: Ean;
    /** @min 0 */
    quantity: number;
    /** @format uuid */
    warehouse_uuid?: string | null;
    /** @format uuid */
    shop_uuid?: string | null;
    /** @min 0 */
    total_quantity?: number;
}

/** @uniqueItems true */
export type EanQuantityWithTotalCollection = EanQuantityWithTotal[];

export interface EanSecurityMargin {
    /** EAN13 code */
    ean: Ean;
    /** @min 0 */
    security_margin: number;
}

/**
 * List of security margins for specific EANs.
 * @uniqueItems true
 */
export type EanSecurityMarginCollection = EanSecurityMargin[];

export interface EanForcedQuantity {
    /** EAN13 code */
    ean: Ean;
    /** @min 0 */
    quantity: number;
    /** @default false */
    compute_quantity?: boolean;
    /** @default false */
    keep_current_quantity?: boolean;
    /** @default false */
    adjust_quantity?: boolean;
}

/** @uniqueItems true */
export type EanForcedQuantityCollection = EanForcedQuantity[];

export interface EanUpdateModeCorrection {
    /** EAN13 code */
    ean: Ean;
    /** @min 0 */
    quantity: number;
    update_mode: string;
}

/** @uniqueItems true */
export type EanUpdateModeCorrectionCollection = EanUpdateModeCorrection[];

export interface EanShopStockCorrection {
    /** EAN13 code */
    ean: Ean;
    /** @min 0 */
    quantity: number;
}

/** @uniqueItems true */
export type EanShopStockCorrectionCollection = EanShopStockCorrection[];

export interface ScheduledUpdate {
    id: number;
    /** @minLength 1 */
    title: string;
    /** @minLength 1 */
    brand: string;
    /** @minLength 1 */
    site?: string;
    workspace_id?: number;
    /** @minLength 1 */
    workspace_name?: string;
    sale_id: number;
    /** @minLength 1 */
    sale_name: string;
    /**
     * When the first step will be executed.
     * @format date-time
     * @example "2023-02-14T09:00:00+01:00"
     */
    scheduled_execution_date: string;
    /**
     * When the scheduled update will recur for the last time.
     * @format date-time
     * @example "2023-02-14T09:00:00+01:00"
     */
    recurrence_end_date?: string | null;
    /** Current config step */
    config_step?: 'STEP1' | 'STEP2' | 'STEP3' | 'STEP4' | 'STEP5' | 'READY';
    /** Online status */
    status: 'Online' | 'Offline' | 'Terminated';
    default_security_margin: number;
    /**
     * Date of creation of the scheduled update
     * @format date-time
     * @example "2022-02-14T09:00:00+01:00"
     */
    created_at: string;
    /**
     * Date of the last update of the scheduled update
     * @format date-time
     * @example "2022-02-14T10:00:00+01:00"
     */
    updated_at: string;
    updated_by: User;
    steps: ScheduledUpdateStepCollection;
}

/** @uniqueItems true */
export type ScheduledUpdateCollection = ScheduledUpdate[];

export interface ScheduledUpdateStep {
    id: number;
    /**
     * When the step will be executed.
     * @format date-time
     * @example "2023-02-14T09:00:00+01:00"
     */
    scheduled_execution_date: string;
    /**
     * Which percentage of available stock will be made available.
     * @min 1
     * @max 100
     */
    availability_percent: number;
    /**
     * When the step has been executed.
     * @format date-time
     * @example "2022-02-14T09:00:00+01:00"
     */
    executed_at: string | null;
}

/** @uniqueItems true */
export type ScheduledUpdateStepCollection = ScheduledUpdateStep[];

export interface ScheduledUpdateStepWithEans {
    id: number;
    /**
     * When the step will be executed.
     * @format date-time
     * @example "2023-02-14T09:00:00+01:00"
     */
    scheduled_execution_date: string;
    /**
     * Which percentage of available stock will be made available.
     * @min 1
     * @max 100
     */
    availability_percent: number;
    /**
     * When the step has been executed.
     * @format date-time
     * @example "2022-02-14T09:00:00+01:00"
     */
    executed_at: string | null;
    /** @uniqueItems true */
    eans: ScheduledUpdateStepEan[];
}

export interface ScheduledUpdateStepEan {
    /** EAN13 code */
    ean: Ean;
    /**
     * The quantity that is made available.
     * @min 0
     */
    quantity: number;
}

export interface ScheduledUpdateEan {
    id: number;
    /** EAN13 code */
    ean: Ean;
    product_code: string;
    previous_product_code: string;
    model_name: string;
    color: string;
    size: string;
    image: string;
    price_eur: string;
    is_authoritative: boolean;
}

export interface ScheduledUpdateEanAutomaticUpdateMode {
    id: number;
    /** EAN13 code */
    ean: Ean;
    product_code: string;
    previous_product_code: string;
    model_name: string;
    color: string;
    size: string;
    image: string;
    price_eur: string;
    is_authoritative: boolean;
    update_mode: 'Automatic';
    /**
     * The EAN's security margin.
     * @min 0
     */
    security_margin: number;
}

export interface ScheduledUpdateEanBlockedUpdateMode {
    id: number;
    /** EAN13 code */
    ean: Ean;
    product_code: string;
    previous_product_code: string;
    model_name: string;
    color: string;
    size: string;
    image: string;
    price_eur: string;
    is_authoritative: boolean;
    update_mode: 'Blocked';
}

/** An EAN with the Forced update mode */
export interface ScheduledUpdateEanForcedUpdateMode {
    id: number;
    /** EAN13 code */
    ean: Ean;
    product_code: string;
    previous_product_code: string;
    model_name: string;
    color: string;
    size: string;
    image: string;
    price_eur: string;
    is_authoritative: boolean;
    update_mode: 'Forced';
    /** @min 0 */
    quantity: number;
    /** @default false */
    compute_quantity?: boolean;
    /** @default false */
    keep_current_quantity?: boolean;
    /** @default false */
    adjust_quantity?: boolean;
    /**
     * The EAN's stock in the buckets WebAvailable and ProjectedInWarehouse.
     * @uniqueItems true
     */
    stocks: Bucket[];
}

/** @uniqueItems true */
export type ScheduledUpdateEanCollection = (
    | ScheduledUpdateEanAutomaticUpdateMode
    | ScheduledUpdateEanBlockedUpdateMode
    | ScheduledUpdateEanForcedUpdateMode
    | (ScheduledUpdateEanAutomaticUpdateMode & ScheduledUpdateEanBlockedUpdateMode & ScheduledUpdateEanForcedUpdateMode)
)[];

export interface User {
    /** @maxLength 36 */
    keycloak_user_id: string;
    username?: string;
}

export interface BucketStockRangeFilter {
    bucket_type: string;
    /** @min 0 */
    minimum?: number;
    /** @min 0 */
    maximum?: number;
}

export interface AutomaticAdjustmentStatus {
    enabled: boolean;
    /**
     * Date of the last update of the scheduled update
     * @format date-time
     * @example "2022-02-14T10:00:00+01:00"
     */
    updated_at: string;
    updated_by: User;
}

export interface AutomaticAdjustmentDate {
    /**
     * Date of the last automatic adjustment
     * @format date-time
     * @example "2022-02-14T10:00:00+01:00"
     */
    executed_at: string;
}

export interface WarehouseStockUpdateDate {
    /**
     * Date of the last update of the warehouse stock
     * @format date-time
     * @example "2022-02-14T10:00:00+01:00"
     */
    updated_at: string;
}

export interface WarehouseStockUpdateFile {
    /**
     * Name of the last warehouse stock update file
     * @example "Info_Article_20220712_160108.csv"
     */
    filename: string;
    /**
     * Date of creation of the diff
     * @format date-time
     * @example "2022-02-14T10:00:00+01:00"
     */
    diff_created_at: string;
    /**
     * Date of the last update of the warehouse stock
     * @format date-time
     * @example "2022-02-14T10:00:00+01:00"
     */
    updated_at: string;
}

export interface EanOnlineStatus {
    /** EAN13 code */
    ean: Ean;
    /** @uniqueItems true */
    stocks: Bucket[];
    web_status: 'Available' | 'Unavailable' | 'InCart';
    lms_status: 'Available' | 'Unavailable' | 'InCart';
}

/** @uniqueItems true */
export type EanOnlineStatusCollection = EanOnlineStatus[];

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseFormat;
    /** request body */
    body?: unknown;
    /** base url */
    baseUrl?: string;
    /** request cancellation token */
    cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
    baseUrl?: string;
    baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
    securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
    customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
    data: D;
    error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
    public baseUrl: string = 'http://stock.sezane.localhost:8081';
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private abortControllers = new Map<CancelToken, AbortController>();
    private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

    private baseApiParams: RequestParams = {
        credentials: 'same-origin',
        headers: {},
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    };

    constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
        Object.assign(this, apiConfig);
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected encodeQueryParam(key: string, value: any) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
    }

    protected addQueryParam(query: QueryParamsType, key: string) {
        return this.encodeQueryParam(key, query[key]);
    }

    protected addArrayQueryParam(query: QueryParamsType, key: string) {
        const value = query[key];
        return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
    }

    protected toQueryString(rawQuery?: QueryParamsType): string {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter(key => 'undefined' !== typeof query[key]);
        return keys
            .map(key =>
                Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)
            )
            .join('&');
    }

    protected addQueryParams(rawQuery?: QueryParamsType): string {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : '';
    }

    private contentFormatters: Record<ContentType, (input: any) => any> = {
        [ContentType.Json]: (input: any) =>
            input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
        [ContentType.Text]: (input: any) =>
            input !== null && typeof input !== 'string' ? JSON.stringify(input) : input,
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(
                    key,
                    property instanceof Blob
                        ? property
                        : typeof property === 'object' && property !== null
                        ? JSON.stringify(property)
                        : `${property}`
                );
                return formData;
            }, new FormData()),
        [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
    };

    protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        };
    }

    protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                return abortController.signal;
            }
            return void 0;
        }

        const abortController = new AbortController();
        this.abortControllers.set(cancelToken, abortController);
        return abortController.signal;
    };

    public abortRequest = (cancelToken: CancelToken) => {
        const abortController = this.abortControllers.get(cancelToken);

        if (abortController) {
            abortController.abort();
            this.abortControllers.delete(cancelToken);
        }
    };

    public request = async <T = any, E = any>({
        body,
        secure,
        path,
        type,
        query,
        format,
        baseUrl,
        cancelToken,
        ...params
    }: FullRequestParams): Promise<HttpResponse<T, E>> => {
        const secureParams =
            ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const queryString = query && this.toQueryString(query);
        const payloadFormatter = this.contentFormatters[type || ContentType.Json];
        const responseFormat = format || requestParams.format;

        return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
            ...requestParams,
            headers: {
                ...(requestParams.headers || {}),
                ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
            },
            signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
            body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
        }).then(async response => {
            const r = response as HttpResponse<T, E>;
            r.data = null as unknown as T;
            r.error = null as unknown as E;

            const data = !responseFormat
                ? r
                : await response[responseFormat]()
                      .then(data => {
                          if (r.ok) {
                              r.data = data;
                          } else {
                              r.error = data;
                          }
                          return r;
                      })
                      .catch(e => {
                          r.error = e;
                          return r;
                      });

            if (cancelToken) {
                this.abortControllers.delete(cancelToken);
            }

            if (!response.ok) throw data;
            return data;
        });
    };
}

/**
 * @title Stock API
 * @version 0.0.1
 * @baseUrl http://stock.sezane.localhost:8081
 * @contact Tech Team Sezane <technology@sezane.com>
 *
 * An API to manipulate stock levels for our EANs.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
    stock = {
        /**
         * @description Called when the customer adds a given quantity of an EAN to their web cart. The quantities are taken from the available web stock. Move a quantity of EAN from the bucket 'WebAvailable' to the bucket 'WebCart'. If the requested quantity is higher than the current value of the bucket 'WebAvailable', an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement, cart
         * @name AddToCart
         * @summary Add to cart
         * @request POST:/stock/v1/add-to-cart/{ean}
         * @secure
         */
        addToCart: (
            ean: Ean,
            data: {
                /** @min 1 */
                quantity: number;
            },
            query?: {
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
                /** The prioritized target warehouse's UUID separated by commas. */
                warehouse_uuids?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperationWithWarehouse, Rfc7807>({
                path: `/stock/v1/add-to-cart/${ean}`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the customer adds various quantities of multiple EANs to the web cart. The quantities are taken from the available web stock. Move multiple quantities of EANs from the bucket 'WebAvailable' to the bucket 'WebCart'. If one of the requested quantities is higher than its corresponding current value in the bucket 'WebAvailable', an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement, purchase
         * @name AddMultipleToCart
         * @summary Add multiple to cart
         * @request POST:/stock/v1/add-to-cart
         * @secure
         */
        addMultipleToCart: (
            data: EanQuantityCollection,
            query?: {
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/add-to-cart`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the support staff adds a given quantity of an EAN to the web cart for a reorder. The quantities are taken from the available web stock, and may be added to the cart even if there is no available web stock anymore, as long as there is enough warehouse stock. Move a quantity of EAN from the bucket 'WebAvailable' to the bucket 'WebCart' if possible. If one or more of the requested quantities is higher than its corresponding current value in the bucket 'WebAvailable', then the remaining quantities are added to `WebCart` as long as the total quantity does not exceed the quantity in the bucket 'ProjectedInWarehouse'. If the value in the bucket 'ProjectedInWarehouse' is inferior to the total requested quantity, an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement, cart
         * @name AddToSupportCart
         * @summary Add to support cart
         * @request POST:/stock/v1/add-to-support-cart/{ean}
         * @secure
         */
        addToSupportCart: (
            ean: Ean,
            data: {
                /** @min 1 */
                quantity: number;
            },
            query?: {
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/add-to-support-cart/${ean}`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the support staff adds a given quantity of an EAN to the web cart for a reorder. The quantities are taken from the available web stock, and may be added to the cart even if there is no available web stock anymore, as long as there is enough warehouse stock. Move multiple quantities of EANs from the bucket 'WebAvailable' to the bucket 'WebCart' if possible. If one or more of the requested quantities is higher than its corresponding current value in the bucket 'WebAvailable', then the remaining quantities are added to `WebCart` as long as the total quantity does not exceed the quantity in the bucket 'ProjectedInWarehouse'. If the value in the bucket 'ProjectedInWarehouse' is inferior to the total requested quantity, an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement, cart
         * @name AddMultipleToSupportCart
         * @summary Add multiple to support cart
         * @request POST:/stock/v1/add-to-support-cart
         * @secure
         */
        addMultipleToSupportCart: (
            data: EanQuantityCollection,
            query?: {
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/add-to-support-cart`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the customer removes a given quantity of an EAN from the web cart. The quantity is moved back to the available web stock. Move as much quantity as possible of an EAN from the bucket 'WebCart' to the bucket 'WebAvailable'. If the requested quantity is higher than the current value of the bucket 'WebCart', the current value falls to zero. The quantities that could not be moved are returned in the "failure.eans" key.
         *
         * @tags stock movement, cart
         * @name RemoveFromCart
         * @summary Remove from cart
         * @request POST:/stock/v1/remove-from-cart/{ean}
         * @secure
         */
        removeFromCart: (
            ean: Ean,
            data: {
                /** @min 1 */
                quantity: number;
            },
            query?: {
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
                /** The warehouse's UUID. */
                warehouse_uuid?: WarehouseUUID;
            },
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/remove-from-cart/${ean}`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the customer removes various quantities of multiple EANs from the web cart. The quantities are moved back to the available web stock. Move multiple quantities of EANs from the bucket 'WebCart' to the bucket 'WebAvailable'. If one of the requested quantities is higher than its corresponding current value in the bucket 'WebCart', the current value falls to zero. The quantities that could not be moved are returned in the "failure.eans" key.
         *
         * @tags stock movement, cart
         * @name RemoveMultipleFromCart
         * @summary Remove multiple from cart
         * @request POST:/stock/v1/remove-from-cart
         * @secure
         */
        removeMultipleFromCart: (
            data: EanQuantityCollection,
            query?: {
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/remove-from-cart`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the customer's web cart expires. The quantities are moved back to the available web stock. Move multiple quantities of EANs from the bucket 'WebCart' to the bucket 'WebAvailable'. If one of the requested quantities is higher than its corresponding current value in the bucket 'WebCart', the current value falls to zero. The quantities that could not be moved are returned in the "failure.eans" key.
         *
         * @tags stock movement, cart
         * @name EmptyCart
         * @summary Empty cart
         * @request POST:/stock/v1/empty-cart
         * @secure
         */
        emptyCart: (
            data: EanQuantityCollection,
            query?: {
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/empty-cart`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the support staff removes various quantities of multiple EANs from the web cart. The quantities are moved back to the available web stock. Remove as much quantity as possible of an EAN from the bucket 'WebCart'. If the requested quantity is higher than the current value of the bucket 'WebCart', the current value falls to zero. The quantity that could not be removed is returned in the "failure.eans" key.
         *
         * @tags stock movement, cart
         * @name RemoveFromSupportCart
         * @summary Remove from support cart
         * @request POST:/stock/v1/remove-from-support-cart/{ean}
         * @secure
         */
        removeFromSupportCart: (
            ean: Ean,
            data: {
                /** @min 1 */
                quantity: number;
            },
            query?: {
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/remove-from-support-cart/${ean}`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the support staff's cart expires, or the support staff cancels the reorder. The quantities are moved back to the available web stock. Remove multiple quantities of EANs from the bucket 'WebCart'. If one of the requested quantities is higher than its corresponding current value in the bucket 'WebCart', the current value falls to zero. The quantities that could not be removed are returned in the "failure.eans" key.
         *
         * @tags stock movement, cart
         * @name EmptySupportCart
         * @summary Empty support cart
         * @request POST:/stock/v1/empty-support-cart
         * @secure
         */
        emptySupportCart: (
            data: EanQuantityCollection,
            query?: {
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/empty-support-cart`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called after an order has been made, to add gift EANs to the order. Move a quantity of EAN from the bucket 'WebAvailable' to the bucket 'WebPurchased'. If the requested quantity is higher than the current value of the bucket 'WebAvailable', an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement, purchase
         * @name AddToPurchase
         * @summary Add to purchase
         * @request POST:/stock/v1/add-to-purchase/{ean}
         * @secure
         */
        addToPurchase: (
            ean: Ean,
            data: {
                /** @min 1 */
                quantity: number;
            },
            query?: {
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
                /** The warehouse's UUID. */
                warehouse_uuid?: WarehouseUUID;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/add-to-purchase/${ean}`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the payment has been made by the customer, and the order is ready to be created. Move multiple quantities of EANs from the bucket 'WebCart' to the bucket 'WebPurchased'. If one of the requested quantities is higher than its corresponding current value in the bucket 'WebCart', an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement, purchase
         * @name CreatePurchase
         * @summary Create purchase
         * @request POST:/stock/v1/create-purchase
         * @secure
         */
        createPurchase: (
            data: EanQuantityCollection,
            query?: {
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/create-purchase`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the payment has been made by the customer, and the order is ready to be created. This is a best effort version, that is used for packaging EANs. Move multiple quantities of EANs from the bucket 'WebCart' to the bucket 'WebPurchased'. If one of the requested quantities is higher than its corresponding current value in the bucket 'WebCart', the current value falls to zero. The quantities that could not be moved are returned in the "failure.eans" key.
         *
         * @tags stock movement, purchase
         * @name CreatePurchaseBestEffort
         * @summary Create purchase (best effort version)
         * @request POST:/stock/v1/create-purchase-best-effort
         * @secure
         */
        createPurchaseBestEffort: (
            data: EanQuantityCollection,
            query?: {
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/create-purchase-best-effort`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the order is validated and target warehouse is different from the default warehouse. Switch multiple quantities of EANs from the bucket 'WebPurchased' of the default warehouse to the bucket 'WebPurchased' of the target warehouse. If one of the requested quantities is higher than its corresponding current value available in the targeted warehouse,  an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement, purchase
         * @name SwitchPurchaseWarehouse
         * @summary Switch quantity of purchase to another warehouse
         * @request POST:/stock/v1/switch-purchase-warehouse
         * @secure
         */
        switchPurchaseWarehouse: (
            query: {
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
                /** The destination warehouse's UUID. */
                destination_warehouse_uuid: WarehouseUUID;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/switch-purchase-warehouse`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when a customer cancels their order and this order has been switched to another warehouse. Remove multiple quantities of EANs from the bucket 'WebPurchased' in the given warehouse. If one of the requested quantities is higher than its corresponding current value in the bucket 'WebPurchased', the current value falls to zero. The quantities that could not be moved are returned in the "failure.eans" key.
         *
         * @tags stock movement, purchase
         * @name CancelSwitchedPurchaseWarehouse
         * @summary Cancel switched purchase warehouse
         * @request POST:/stock/v1/cancel-switched-purchase-warehouse
         * @secure
         */
        cancelSwitchedPurchaseWarehouse: (
            query: {
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
                /** The warehouse's UUID. */
                warehouse_uuid: WarehouseUUID;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/cancel-switched-purchase-warehouse`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called for optimization, when items from an OMS Cart are reserved on different warehouses,  to try to send items from the same warehouse. Switch multiple quantities of EANs from the bucket 'WebCart' of the source warehouse to the bucket 'WebCart' of the target warehouse. If one of the requested quantities is higher than its corresponding current value available in the targeted warehouse,  an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement, cart
         * @name SwitchCartWarehouse
         * @summary Switch quantity of WebCart bucket to another warehouse
         * @request POST:/stock/v1/switch-cart-warehouse
         * @secure
         */
        switchCartWarehouse: (
            query: {
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
                /** The destination warehouse's UUID. */
                destination_warehouse_uuid: WarehouseUUID;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/switch-cart-warehouse`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called for optimization, when items from an OMS Cart are reserved on different warehouses,  to try to send items from the same warehouse. Switch multiple quantities of EANs from the bucket 'WebCart' of the source warehouse to the bucket 'WebCart' of the target warehouse. If one of the requested quantities is higher than its corresponding current value available in the targeted warehouse,  the current value falls to zero. The quantities that could not be moved are returned in in the "failure.eans" key.
         *
         * @tags stock movement, cart
         * @name SwitchCartWarehouseBestEffort
         * @summary Switch quantity of WebCart bucket to another warehouse (best effort version)
         * @request POST:/stock/v1/switch-cart-warehouse-best-effort
         * @secure
         */
        switchCartWarehouseBestEffort: (
            query: {
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
                /** The destination warehouse's UUID. */
                destination_warehouse_uuid: WarehouseUUID;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/switch-cart-warehouse-best-effort`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when an order cannot be cancelled anymore (the purchase is final after 70 minutes), and the order is actually going to be handled at the warehouse. Decrement the values of the buckets 'WebPurchased' and 'ProjectedInWarehouse' for multiple EANs. If one of the requested quantities is higher than its corresponding current value in the bucket 'WebPurchased', that bucket's value is now zero, and we still decrement the bucket 'ProjectedInWarehouse' by the requested quantity, even if that makes it negative.
         *
         * @tags stock movement, purchase
         * @name ConfirmPurchase
         * @summary Confirm purchase
         * @request POST:/stock/v1/confirm-purchase
         * @secure
         */
        confirmPurchase: (
            data: EanQuantityCollection,
            query?: {
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
                /** The warehouse's UUID. */
                warehouse_uuid?: WarehouseUUID;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/confirm-purchase`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called either when a customer cancels their order and their cart has expired, or to cancel the order of a certain quantity of EANs (typically when an order includes packaging but there is not enough packaging left). Move multiple quantities of EANs from the bucket 'WebPurchased' to the bucket 'WebAvailable'. If one of the requested quantities is higher than its corresponding current value in the bucket 'WebPurchased', the current value falls to zero. The quantities that could not be moved are returned in the "failure.eans" key.
         *
         * @tags stock movement, purchase
         * @name CancelPurchase
         * @summary Cancel purchase
         * @request POST:/stock/v1/cancel-purchase
         * @secure
         */
        cancelPurchase: (
            data: EanQuantityCollection,
            query?: {
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/cancel-purchase`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called either when the customer service cancels a reorder and the cart has expired, or to cancel the order of a certain quantity of EANs (typically when an order includes packaging but there is not enough packaging left). Remove multiple quantities of EANs from the bucket 'WebPurchased'. If one of the requested quantities is higher than its corresponding current value in the bucket 'WebPurchased', the current value falls to zero. The quantities that could not be moved are returned in the "failure.eans" key.
         *
         * @tags stock movement, purchase
         * @name CancelSupportPurchase
         * @summary Cancel support purchase
         * @request POST:/stock/v1/cancel-support-purchase
         * @secure
         */
        cancelSupportPurchase: (
            data: EanQuantityCollection,
            query?: {
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/cancel-support-purchase`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called either when a customer cancels their order and their cart has not expired yet, or when the payment failed. Move multiple quantities of EANs from the bucket 'WebPurchased' to the bucket 'WebCart'. If one of the requested quantities is higher than its corresponding current value in the bucket 'WebPurchased', the current value falls to zero. The quantities that could not be moved are returned in the "failure.eans" key.
         *
         * @tags stock movement, purchase
         * @name MoveBackToCart
         * @summary Move back to cart
         * @request POST:/stock/v1/move-back-to-cart
         * @secure
         */
        moveBackToCart: (
            data: EanQuantityCollection,
            query?: {
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/move-back-to-cart`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the retail staff orders some EANs for a shop. Quantities are taken from the available web stock, and from the projected warehouse stock as a fallback. If there are not enough quantities in the projected warehouse stock, the request fails. Move multiple quantities of EANs from the bucket 'WebAvailable' to the bucket 'RetailToSend'. If one or more of the requested quantities is higher than its corresponding current value in the bucket 'WebAvailable', then the remaining quantities are taken from the bucket `ProjectedInWarehouse`. If some values in the bucket `ProjectedInWarehouse` are inferior to the remaining quantities, then the corresponding EANs are listed in the 'failure.eans' key in the response body and we don't move quantities for them.   If the move is successful, the bucket 'RetailTotalToSend' is incremented by the same quantities, for the given shop_uuid.
         *
         * @tags stock movement, retail-purchase
         * @name CreateRetailPurchase
         * @summary Create retail purchase
         * @request POST:/stock/v1/create-retail-purchase
         * @secure
         */
        createRetailPurchase: (
            data: EanQuantityCollection,
            query?: {
                /** Shop UUID */
                shop_uuid?: ShopUUID;
                /** The warehouse's UUID. */
                warehouse_uuid?: WarehouseUUID;
                'context[retail_order_id]'?: string;
                'context[retail_operation]'?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/create-retail-purchase`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the retail staff orders some EANs for a shop. Quantities are taken from the available web stock, and from the projected warehouse stock as a fallback. If there are not enough quantities in the projected warehouse stock, the request still works. Move multiple quantities of EANs from the bucket 'WebAvailable' to the bucket 'RetailToSend'. If one or more of the requested quantities is higher than its corresponding current value in the bucket 'WebAvailable', then the corresponding value in 'WebAvailable' is reduced to zero, but the requested quantity is still added to 'RetailToSend'.   The bucket 'RetailTotalToSend' is incremented by the same quantities, for the given shop_uuid.
         *
         * @tags stock movement, retail-purchase
         * @name CreateForcedRetailPurchase
         * @summary Create forced retail purchase
         * @request POST:/stock/v1/create-forced-retail-purchase
         * @secure
         */
        createForcedRetailPurchase: (
            data: EanQuantityCollection,
            query?: {
                /** Shop UUID */
                shop_uuid?: ShopUUID;
                /** The warehouse's UUID. */
                warehouse_uuid?: WarehouseUUID;
                'context[retail_order_id]'?: string;
                'context[retail_operation]'?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/create-forced-retail-purchase`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the retail staff cancels a retail purchase. The quantities are not moved back to the available web stock or to the projected warehouse stock (which will be replenished when the next warehouse stock update happens). Decrement the value of the bucket 'RetailToSend' for multiple EANs. If one or more of the requested quantities is higher than its corresponding current value in the bucket 'RetailToSend', then the corresponding EANs are listed in the 'failure.eans' key in the response body and we don't decrement quantities for them.   If the decrementation is successful, the bucket 'RetailTotalToSend' is decremented by the same quantities, for the given shop_uuid.
         *
         * @tags stock movement, retail-purchase
         * @name CancelRetailPurchase
         * @summary Cancel retail purchase
         * @request POST:/stock/v1/cancel-retail-purchase
         * @secure
         */
        cancelRetailPurchase: (
            data: EanQuantityCollection,
            query?: {
                /** Shop UUID */
                shop_uuid?: ShopUUID;
                /** The warehouse's UUID. */
                warehouse_uuid?: WarehouseUUID;
                'context[retail_order_id]'?: string;
                'context[retail_operation]'?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/cancel-retail-purchase`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when a retail purchase is confirmed. The projected warehouse stock is decremented by the given quantities. Decrement the values of the buckets 'RetailToSend' and 'ProjectedInWarehouse' for multiple EANs. If one or more of the requested quantities is higher than its corresponding current value in the bucket 'RetailToSend', then the corresponding EANs are listed in the 'failure.eans' key in the response body and we don't decrement the values for them.
         *
         * @tags stock movement, retail-purchase
         * @name ConfirmRetailPurchase
         * @summary Confirm retail purchase
         * @request POST:/stock/v1/confirm-retail-purchase
         * @secure
         */
        confirmRetailPurchase: (
            data: EanQuantityCollection,
            query?: {
                /** Shop UUID */
                shop_uuid?: ShopUUID;
                /** The warehouse's UUID. */
                warehouse_uuid?: WarehouseUUID;
                'context[retail_order_id]'?: string;
                'context[retail_operation]'?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/confirm-retail-purchase`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the retail staff orders some EANs for a shop, from a given warehouse's retail supply stock. Move multiple quantities of EANs from the bucket 'RealInWarehouseRetailSupply' to the bucket 'RetailToSend'. If some values in the bucket `RealInWarehouseRetailSupply` are inferior to the remaining quantities, then the corresponding EANs are listed in the 'failure.eans' key in the response body and we don't move quantities for them.   If the move is successful, the bucket 'RetailTotalToSend' is incremented by the same quantities, for the given shop_uuid.
         *
         * @tags stock movement, retail-purchase
         * @name CreateRetailSupplyPurchase
         * @summary Create retail supply purchase
         * @request POST:/stock/v1/create-retail-supply-purchase
         * @secure
         */
        createRetailSupplyPurchase: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                /** The warehouse's UUID. */
                warehouse_uuid: WarehouseUUID;
                'context[retail_order_id]'?: string;
                'context[retail_operation]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<PartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/create-retail-supply-purchase`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the retail staff orders some EANs for a shop, from a given warehouse's retail supply stock. If there are not enough quantities in the warehouse's retail supply stock, the request still works. Move multiple quantities of EANs from the bucket 'RealInWarehouseRetailSupply' to the bucket 'RetailToSend'. If one or more of the requested quantities is higher than its corresponding current value in the bucket 'RealInWarehouseRetailSupply', then the corresponding value in 'RealInWarehouseRetailSupply' is reduced to zero, but the requested quantity is still added to 'RetailToSend'.   The bucket 'RetailTotalToSend' is incremented by the same quantities, for the given shop_uuid.
         *
         * @tags stock movement, retail-purchase
         * @name CreateForcedRetailSupplyPurchase
         * @summary Create forced retail supply purchase
         * @request POST:/stock/v1/create-forced-retail-supply-purchase
         * @secure
         */
        createForcedRetailSupplyPurchase: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                /** The warehouse's UUID. */
                warehouse_uuid: WarehouseUUID;
                'context[retail_order_id]'?: string;
                'context[retail_operation]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/create-forced-retail-supply-purchase`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the retail staff cancels a retail supply purchase. Move multiple quantities of EANs from the bucket 'RetailToSend' to the bucket 'RealInWarehouseRetailSupply'. If one of the requested quantities is higher than its corresponding current value in the bucket 'RetailToSend', the current value falls to zero. The quantities that could not be moved are returned in the "failure.eans" key.   If the decrementation is successful, the bucket 'RetailTotalToSend' is decremented by the same quantities, for the given shop_uuid.
         *
         * @tags stock movement, retail-purchase
         * @name CancelRetailSupplyPurchase
         * @summary Cancel retail supply purchase
         * @request POST:/stock/v1/cancel-retail-supply-purchase
         * @secure
         */
        cancelRetailSupplyPurchase: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                /** The warehouse's UUID. */
                warehouse_uuid: WarehouseUUID;
                'context[retail_order_id]'?: string;
                'context[retail_operation]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/cancel-retail-supply-purchase`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when a retail purchase is confirmed. The projected warehouse stock is decremented by the given quantities. Decrement the values of the buckets 'RetailToSend' and 'ProjectedInWarehouse' for multiple EANs. If one or more of the requested quantities is higher than its corresponding current value in the bucket 'RetailToSend', then the corresponding EANs are listed in the 'failure.eans' key in the response body and we don't decrement the values for them.
         *
         * @tags stock movement, retail-purchase
         * @name ConfirmRetailSupplyPurchase
         * @summary Confirm retail supply purchase
         * @request POST:/stock/v1/confirm-retail-supply-purchase
         * @secure
         */
        confirmRetailSupplyPurchase: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                /** The warehouse's UUID. */
                warehouse_uuid: WarehouseUUID;
                'context[retail_order_id]'?: string;
                'context[retail_operation]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<PartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/confirm-retail-supply-purchase`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the customer adds a given quantity of an EAN to the "last minute shopping" (LMS) cart, for delivery by a courier. The quantity is taken from the available shop stock in the designated shop for LMS. Move a quantity of EAN from the bucket 'ShopAvailable' to the bucket 'ShopLmsCart' of the given shop UUID. If the requested quantity is higher than the current value of the bucket 'ShopAvailable', an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement, cart
         * @name AddToLmsCart
         * @summary Add to LMS cart
         * @request POST:/stock/v1/add-to-lms-cart/{ean}
         * @secure
         */
        addToLmsCart: (
            ean: Ean,
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            data: {
                /** @min 1 */
                quantity: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/add-to-lms-cart/${ean}`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the customer removes a given quantity of an EAN from the LMS cart. The quantity is moved back to the available shop stock in the designated shop for LMS. Move a quantity of EAN from the bucket 'ShopLmsCart' to the bucket 'ShopAvailable' of the given shop UUID. If the requested quantity is higher than the current value of the bucket 'WebCart', an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement, cart
         * @name RemoveFromLmsCart
         * @summary Remove from LMS cart
         * @request POST:/stock/v1/remove-from-lms-cart/{ean}
         * @secure
         */
        removeFromLmsCart: (
            ean: Ean,
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            data: {
                /** @min 1 */
                quantity: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/remove-from-lms-cart/${ean}`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the customer's LMS cart expires. The quantities are moved back to the available shop stock in the designated shop for LMS. Move multiple quantities of EANs from the bucket 'ShopLmsCart' to the bucket 'ShopAvailable' of the given shop UUID. If one of the requested quantities is higher than its corresponding current value in the bucket 'ShopLmsCart', the current value falls to zero. The quantities that could not be moved are returned in the "failure.eans" key.
         *
         * @tags stock movement, cart
         * @name EmptyLmsCart
         * @summary Empty LMS cart
         * @request POST:/stock/v1/empty-lms-cart
         * @secure
         */
        emptyLmsCart: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/empty-lms-cart`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the payment has been made by the customer, and the LMS order is ready to be created. Move multiple quantities of EANs from the bucket 'ShopLmsCart' to the bucket 'ShopLmsPurchased' of the given shop UUID. If one of the requested quantities is higher than its corresponding current value in the bucket 'ShopLmsCart', an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement, purchase
         * @name CreateLmsPurchase
         * @summary Create LMS purchase
         * @request POST:/stock/v1/create-lms-purchase
         * @secure
         */
        createLmsPurchase: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/create-lms-purchase`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the payment has been made by the customer, and the LMS order is ready to be created. This is a best effort version, that is used for packaging EANs. Move multiple quantities of EANs from the bucket 'ShopLmsCart' to the bucket 'ShopLmsPurchased' of the given shop UUID. If one of the requested quantities is higher than its corresponding current value in the bucket 'ShopLmsCart', the current value falls to zero. The quantities that could not be moved are returned in the "failure.eans" key.
         *
         * @tags stock movement, purchase
         * @name CreateLmsPurchaseBestEffort
         * @summary Create LMS purchase (best effort version)
         * @request POST:/stock/v1/create-lms-purchase-best-effort
         * @secure
         */
        createLmsPurchaseBestEffort: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/create-lms-purchase-best-effort`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when an LMS order cannot be cancelled anymore (the purchase is final after 70 minutes), and the order is actually going to be handled at the designated shop for LMS. Decrement the value of the bucket 'ShopLmsPurchased' for multiple EANs. If one of the requested quantities is higher than its corresponding current value in the bucket 'ShopLmsPurchased', an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement, purchase
         * @name ConfirmLmsPurchase
         * @summary Confirm LMS purchase
         * @request POST:/stock/v1/confirm-lms-purchase
         * @secure
         */
        confirmLmsPurchase: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/confirm-lms-purchase`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called either when a customer cancels their LMS order and their cart has expired, or to cancel the order of a certain quantity of EANs (typically when an order includes packaging but there is not enough packaging left). Move multiple quantities of EANs from the bucket 'ShopLmsPurchased' to the bucket 'ShopAvailable' of the given shop UUID. If one of the requested quantities is higher than its corresponding current value in the bucket 'ShopLmsPurchased', the current value falls to zero. The quantities that could not be moved are returned in the "failure.eans" key.
         *
         * @tags stock movement, purchase
         * @name CancelLmsPurchase
         * @summary Cancel LMS purchase
         * @request POST:/stock/v1/cancel-lms-purchase
         * @secure
         */
        cancelLmsPurchase: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/cancel-lms-purchase`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called either when a customer cancels their LMS order and their cart has not expired yet, or when the payment failed. Move multiple quantities of EANs from the bucket 'ShopLmsPurchased' to the bucket 'ShopLmsCart' of the given shop UUID. If one of the requested quantities is higher than its corresponding current value in the bucket 'ShopLmsPurchased', the current value falls to zero. The quantities that could not be moved are returned in the "failure.eans" key.
         *
         * @tags stock movement, purchase
         * @name MoveBackToLmsCart
         * @summary Move back to LMS cart
         * @request POST:/stock/v1/move-back-to-lms-cart
         * @secure
         */
        moveBackToLmsCart: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/move-back-to-lms-cart`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the shop clerk scans an article at the cashier machine. The quantity is taken from the available shop stock. Move a quantity of EAN from the bucket 'ShopAvailable' to the bucket 'ShopCart' of the given shop UUID. If the requested quantity is higher than the current value of the bucket 'ShopAvailable', an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement, cart, shop
         * @name AddToShopCart
         * @summary Add to shop cart
         * @request POST:/stock/v1/add-to-shop-cart/{ean}
         * @secure
         */
        addToShopCart: (
            ean: Ean,
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            data: {
                /** @min 1 */
                quantity: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/add-to-shop-cart/${ean}`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the shop clerk scans an article at the cashier machine. The quantity is taken from the available shop stock. Move a quantity of EAN from the bucket 'ShopAvailable' to the bucket 'ShopCart' of the given shop UUID. If the requested quantity is higher than the current value of the bucket 'ShopAvailable', that bucket's value is now zero, and we still increment the bucket 'ShopCart' by the requested quantity.
         *
         * @tags stock movement, cart, shop
         * @name AddForcedToShopCart
         * @summary Force add to shop cart
         * @request POST:/stock/v1/add-forced-to-shop-cart/{ean}
         * @secure
         */
        addForcedToShopCart: (
            ean: Ean,
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            data: {
                /** @min 1 */
                quantity: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/add-forced-to-shop-cart/${ean}`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the shop clerk cancels the addition of an article at the cashier machine. The quantity is moved back to the available shop stock. Move a quantity of EAN from the bucket 'ShopCart' to the bucket 'ShopAvailable' of the given shop UUID. If the requested quantity is higher than the current value of the bucket 'WebCart', an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement, cart, shop
         * @name RemoveFromShopCart
         * @summary Remove from shop cart
         * @request POST:/stock/v1/remove-from-shop-cart/{ean}
         * @secure
         */
        removeFromShopCart: (
            ean: Ean,
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            data: {
                /** @min 1 */
                quantity: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/remove-from-shop-cart/${ean}`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the shop clerk cancels a customer's entire purchase at the cashier machine. The quantities are moved back to the available shop stock. Move multiple quantities of EANs from the bucket 'ShopCart' to the bucket 'ShopAvailable' of the given shop UUID. If one of the requested quantities is higher than its corresponding current value in the bucket 'ShopCart', the current value falls to zero. The quantities that could not be moved are returned in the "failure.eans" key.
         *
         * @tags stock movement, cart, shop
         * @name EmptyShopCart
         * @summary Empty shop cart
         * @request POST:/stock/v1/empty-shop-cart
         * @secure
         */
        emptyShopCart: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/empty-shop-cart`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the payment has been made by the customer at the shop's cashier machine. The available shop stock is decremented by the given quantities. Decrement the value of the bucket 'ShopCart' for multiple EANs. If one of the requested quantities is higher than its corresponding current value in the bucket 'ShopCart', an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement, purchase, shop
         * @name ConfirmShopPurchase
         * @summary Confirm shop purchase
         * @request POST:/stock/v1/confirm-shop-purchase
         * @secure
         */
        confirmShopPurchase: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/confirm-shop-purchase`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when a shop's staff sends items to be repaired by an external service. The quantities are removed from the available shop stock and moved to a dedicated service stock. Move multiple quantities of EANs from the bucket 'ShopAvailable' to the bucket 'ShopService' of the given shop UUID and service UUID. If one of the requested quantities is higher than its corresponding current value in the bucket 'ShopAvailable', an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement, service, shop
         * @name SendToService
         * @summary Send to service
         * @request POST:/stock/v1/send-to-service
         * @secure
         */
        sendToService: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                /** Service UUID */
                service_uuid: ServiceUUID;
                'context[retail_operation]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/send-to-service`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when a shop's staff confirms the return of items that were repaired by the given external service. The quantities are moved back to the shop's available stock. Move multiple quantities of EANs from the bucket 'ShopService' of the given shop UUID and service UUID to the bucket 'ShopAvailable' of the given shop UUID. If one of the requested quantities is higher than its corresponding current value in the bucket 'ShopService', an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement, service, shop
         * @name ReturnFromService
         * @summary Return from service
         * @request POST:/stock/v1/return-from-service
         * @secure
         */
        returnFromService: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                /** Service UUID */
                service_uuid: ServiceUUID;
                'context[retail_operation]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/return-from-service`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when a customer returns an item to a shop, and the item is from a retail order. The item is considered integrated into the shop's available stock. Increment the value of the bucket 'ShopAvailable' for multiple EANs.
         *
         * @tags stock movement, return, shop
         * @name CreateRetailShopReturn
         * @summary Create retail shop return
         * @request POST:/stock/v1/create-retail-shop-return
         * @secure
         */
        createRetailShopReturn: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/create-retail-shop-return`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when a customer returns an item to a shop, and the item is from a web order. Increment the value of the bucket 'ShopReturned' for multiple EANs.
         *
         * @tags stock movement, return, shop
         * @name CreateWebShopReturn
         * @summary Create web shop return
         * @request POST:/stock/v1/create-web-shop-return
         * @secure
         */
        createWebShopReturn: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/create-web-shop-return`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when a customer returns a defective item to a shop. Increment the value of the bucket 'ShopDefectReturned' for multiple EANs.
         *
         * @tags stock movement, return, shop
         * @name CreateShopDefectReturn
         * @summary Create shop defect return
         * @request POST:/stock/v1/create-shop-defect-return
         * @secure
         */
        createShopDefectReturn: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/create-shop-defect-return`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the customer's order return is confirmed but the quantities will not be added to the shop's stock. Decrement the value of the bucket 'ShopReturned' for multiple EANs. If one of the requested quantities is higher than its corresponding current value in the bucket 'ShopReturned', the current value falls to zero.
         *
         * @tags stock movement, return, shop
         * @name ConfirmShopReturn
         * @summary Confirm shop return
         * @request POST:/stock/v1/confirm-shop-return
         * @secure
         */
        confirmShopReturn: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
                'context[retail_operation]'?: string;
                'context[retail_item_uuid]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/confirm-shop-return`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the customer's defective item return is confirmed but the quantities will not be added to the shop's stock. Decrement the value of the bucket 'ShopDefectReturned' for multiple EANs. If one of the requested quantities is higher than its corresponding current value in the bucket 'ShopDefectReturned', the current value falls to zero.
         *
         * @tags stock movement, return, shop
         * @name ConfirmShopDefectReturn
         * @summary Confirm shop defect return
         * @request POST:/stock/v1/confirm-shop-defect-return
         * @secure
         */
        confirmShopDefectReturn: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/confirm-shop-defect-return`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the customer's order return is confirmed. The quantities are added to the shop's stock. Move multiple quantities of EANs from the bucket 'ShopReturned' to the bucket 'ShopAvailable'. If one of the requested quantities is higher than its corresponding current value in the bucket 'ShopReturned', an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement, return, shop
         * @name MoveReturnToShop
         * @summary Move return to shop
         * @request POST:/stock/v1/move-return-to-shop
         * @secure
         */
        moveReturnToShop: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
                'context[retail_operation]'?: string;
                'context[customer_return_id]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/move-return-to-shop`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the customer's item return is declared to be a defective item. Move multiple quantities of EANs from the bucket 'ShopReturned' to the bucket 'ShopDefectReturned'. If one of the requested quantities is higher than its corresponding current value in the bucket 'ShopReturned', the current value falls to zero. The quantities that could not be moved are returned in the "failure.eans" key.
         *
         * @tags stock movement, return, shop
         * @name MoveReturnToDefect
         * @summary Move return to defect
         * @request POST:/stock/v1/move-return-to-defect
         * @secure
         */
        moveReturnToDefect: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/move-return-to-defect`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the customer's defective item return is declared to be non-defective. Move multiple quantities of EANs from the bucket 'ShopDefectReturned' to the bucket 'ShopReturned'. If one of the requested quantities is higher than its corresponding current value in the bucket 'ShopReturned', the current value falls to zero. The quantities that could not be moved are returned in the "failure.eans" key.
         *
         * @tags stock movement, return, shop
         * @name MoveDefectToReturn
         * @summary Move defect to return
         * @request POST:/stock/v1/move-defect-to-return
         * @secure
         */
        moveDefectToReturn: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/move-defect-to-return`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when a shop's staff returns items in the available shop stock to the warehouse. Decrement the value of the bucket 'ShopAvailable' for multiple EANs. If one of the requested quantities is higher than its corresponding current value in the bucket 'ShopAvailable', an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement, return, shop
         * @name ConfirmRetailReturn
         * @summary Confirm retail return
         * @request POST:/stock/v1/confirm-retail-return
         * @secure
         */
        confirmRetailReturn: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[retail_operation]'?: string;
                'context[retail_item_uuid]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/confirm-retail-return`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description List online status for a collection of EANs.
         *
         * @tags stock view, list
         * @name ListOnlineStatus
         * @summary List online status for a collection of EANs.
         * @request POST:/stock/v1/list-online-status
         * @secure
         */
        listOnlineStatus: (data: EanCollectionBody, params: RequestParams = {}) =>
            this.request<EanOnlineStatusCollection, Rfc7807>({
                path: `/stock/v1/list-online-status`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description List shop stocks for a collection of EANs, in either all shops or in a list of shop uuids. For all EANs, the quantities in each shop bucket are returned.
         *
         * @tags stock view, list, shop
         * @name ListShopStocks
         * @summary List shop stocks
         * @request POST:/stock/v1/list-shop-stocks
         * @secure
         */
        listShopStocks: (
            data: EanCollectionInShopsBody,
            query?: {
                /**
                 * Whether to fetch EAN details (brand, label, etc.)
                 * @default true
                 */
                fetchEanDetails?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<EanStocksCollection, Rfc7807>({
                path: `/stock/v1/list-shop-stocks`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description List stocks for a collection of EANs, and a collection of buckets. If no buckets are specified, all bucket types are taken into account. For all EANs, the quantities in each bucket are returned.
         *
         * @tags stock view, list, shop
         * @name GetStocksForBackend
         * @summary List stocks (for backend)
         * @request POST:/stock/v1/stocks
         * @secure
         */
        getStocksForBackend: (
            data: EanCollectionBody,
            query?: {
                /** @default "EUR" */
                currency_code?: string;
                /**
                 * Locale for the labels
                 * @default "fr"
                 */
                locale?: string;
                /** Shop UUID */
                shop_uuid?: ShopUUID;
                /** Field name on which to order as key, asc/desc direction as value. Supported field names: all bucket types. */
                order?: Record<string, any>;
                /** Only return quantities in the selected buckets. */
                bucket_type?: BucketType[];
                /**
                 * Whether to fetch EAN details (brand, label, etc.)
                 * @default true
                 */
                fetchEanDetails?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<EanStocksCollection, Rfc7807>({
                path: `/stock/v1/stocks`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the retail staff sends a packing list. Increment the bucket `RetailIncomingTransit` for the given shop UUID and warehouse UUId for all EANs. If shop_uuid is not set, it is taken from the input collection. If warehouse_uuid is not set, it is the default warehouse UUID.   The bucket `RetailTotalToSend` is decremented for the given shop UUID and warehouse UUID by the value of the key `total_quantity` for all EANs.
         *
         * @tags stock update, stock movement, shop
         * @name SendRetailPackingList
         * @summary Send retail Packing List
         * @request POST:/stock/v1/send-retail-packing-list
         * @secure
         */
        sendRetailPackingList: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                /** The warehouse's UUID. */
                warehouse_uuid?: WarehouseUUID;
                'context[retail_order_id]'?: string;
                'context[retail_operation]'?: string;
            },
            data: EanQuantityWithTotalCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/send-retail-packing-list`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the retail staff confirms reception of a packing list. Decrement the bucket `RetailIncomingTransit` for the given shop UUID and warehouse UUId for all EANs. If shop_uuid is not set, it is taken from the input collection. If warehouse_uuid is not set, it is the default warehouse UUID. If one of the requested quantities is higher than its corresponding current value in the bucket 'RetailIncomingTransit', the current value falls to zero.
         *
         * @tags stock update, stock movement, shop
         * @name ConfirmRetailPackingList
         * @summary Confirm retail Packing List
         * @request POST:/stock/v1/confirm-retail-packing-list
         * @secure
         */
        confirmRetailPackingList: (
            data: EanQuantityCollection,
            query?: {
                /** Shop UUID */
                shop_uuid?: ShopUUID;
                /** The warehouse's UUID. */
                warehouse_uuid?: WarehouseUUID;
                'context[retail_order_id]'?: string;
                'context[retail_operation]'?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock/v1/confirm-retail-packing-list`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Notify that an update of stocks at the warehouse has been posted. The argument filename should point to a CSV file on our S3 bucket that lists the updated EANs and their new in-stock quantities. If the argument bucket is provided, the CSV file is fetched from that S3 bucket instead.
         *
         * @tags stock update, warehouse
         * @name NotifyWarehouseStockUpdate
         * @summary Notify warehouse stock update
         * @request POST:/stock/v1/notify-warehouse-stock-update
         * @secure
         */
        notifyWarehouseStockUpdate: (
            data: {
                filename: string;
                bucket: string;
            },
            query?: {
                /** The warehouse's UUID. */
                warehouse_uuid?: WarehouseUUID;
            },
            params: RequestParams = {}
        ) =>
            this.request<AcceptedOperation, Rfc7807>({
                path: `/stock/v1/notify-warehouse-stock-update`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Get the current status of automatic adjustment, when it was last updated, and by whom.
         *
         * @tags setting
         * @name ShowAutomaticAdjustmentStatusForBackend
         * @summary Show automatic adjustment status (for backend)
         * @request GET:/stock/v1/automatic-adjustment-status
         * @secure
         */
        showAutomaticAdjustmentStatusForBackend: (
            query?: {
                /** The warehouse's UUID. */
                warehouse_uuid?: WarehouseUUID;
            },
            params: RequestParams = {}
        ) =>
            this.request<AutomaticAdjustmentStatus, Rfc7807>({
                path: `/stock/v1/automatic-adjustment-status`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when a customer return repatriation is sent to logistic.  The quantities are taken from repatriation bucket if possible. Decrement the values of the buckets 'RepatriationCustomerReturn' and 'ProjectedInWarehouse' for multiple EANs of targeted warehouse.  If one of the requested quantities is higher than its corresponding current value in the bucket 'RepatriationCustomerReturn', that bucket's value is now zero, and we still decrement the bucket 'ProjectedInWarehouse' by the requested quantity, even if that makes it negative.
         *
         * @tags stock movement
         * @name ConfirmCustomerReturnRepatriation
         * @summary Confirm customer return repatriation
         * @request POST:/stock/v1/confirm-customer-return-repatriation
         * @secure
         */
        confirmCustomerReturnRepatriation: (
            query: {
                /** The warehouse's UUID. */
                warehouse_uuid: WarehouseUUID;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/confirm-customer-return-repatriation`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when a retail return repatriation is sent to logistic.  The quantities are taken from repatriation bucket if possible. Decrement the values of the buckets 'RepatriationRetailReturn' and 'ProjectedInWarehouse' for multiple EANs of targeted warehouse.  If one of the requested quantities is higher than its corresponding current value in the bucket 'RepatriationRetailReturn', that bucket's value is now zero, and we still decrement the bucket 'ProjectedInWarehouse' by the requested quantity, even if that makes it negative.
         *
         * @tags stock movement
         * @name ConfirmRetailReturnRepatriation
         * @summary Confirm retail return repatriation
         * @request POST:/stock/v1/confirm-retail-return-repatriation
         * @secure
         */
        confirmRetailReturnRepatriation: (
            query: {
                /** The warehouse's UUID. */
                warehouse_uuid: WarehouseUUID;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/confirm-retail-return-repatriation`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when a retail supply repatriation is sent to logistic.  The quantities are taken from repatriation bucket if possible. Decrement the values of the buckets 'RepatriationRetailSupply' for multiple EANs of targeted warehouse.  If one of the requested quantities is higher than its corresponding current value in the bucket 'RepatriationRetailSupply', that bucket's value is now zero.
         *
         * @tags stock movement
         * @name ConfirmRetailSupplyRepatriation
         * @summary Confirm retail supply repatriation
         * @request POST:/stock/v1/confirm-retail-supply-repatriation
         * @secure
         */
        confirmRetailSupplyRepatriation: (
            query: {
                /** The warehouse's UUID. */
                warehouse_uuid: WarehouseUUID;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock/v1/confirm-retail-supply-repatriation`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    };
    stockPrivate = {
        /**
         * @description Called when the shop clerk scans an article at the cashier machine. The quantity is taken from the available shop stock. Move a quantity of EAN from the bucket 'ShopAvailable' to the bucket 'ShopCart' of the given shop UUID. If the requested quantity is higher than the current value of the bucket 'ShopAvailable', that bucket's value is now zero, and we still increment the bucket 'ShopCart' by the requested quantity.
         *
         * @tags stock movement, cart, shop
         * @name AddForcedToShopCartPrivate
         * @summary Force add to shop cart
         * @request POST:/stock-private/v1/add-forced-to-shop-cart/{ean}
         * @secure
         */
        addForcedToShopCartPrivate: (
            ean: Ean,
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[cart_uuid]'?: string;
                'context[order_uuid]'?: string;
                'context[brand_code]'?: string;
                'context[site_code]'?: string;
            },
            data: {
                /** @min 1 */
                quantity: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/add-forced-to-shop-cart/${ean}`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description List stocks for a collection of EANs. For all EANs, the quantities in each bucket are returned.
         *
         * @tags stock view, list, shop
         * @name ListStocks
         * @summary List stocks (deprecated: use /stock/v1/stocks instead)
         * @request POST:/stock-private/v1/list-stocks
         * @deprecated
         * @secure
         */
        listStocks: (
            data: EanCollection,
            query?: {
                /** Shop UUID */
                shop_uuid?: ShopUUID;
                /** Field name on which to order as key, asc/desc direction as value. Supported field names: all bucket types. */
                order?: Record<string, any>;
                'context[retail_order_id]'?: string;
                /**
                 * Whether to fetch EAN details (brand, label, etc.)
                 * @default true
                 */
                fetchEanDetails?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<EanStocksCollection, Rfc7807>({
                path: `/stock-private/v1/list-stocks`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description List stocks for a collection of EANs, and a collection of buckets. If no buckets are specified, all bucket types are taken into account. For all EANs, the quantities in each bucket are returned.
         *
         * @tags stock view, list, shop
         * @name GetStocks
         * @summary List stocks
         * @request POST:/stock-private/v1/stocks
         * @secure
         */
        getStocks: (
            data: EanCollectionBody,
            query?: {
                /** @default "EUR" */
                currency_code?: string;
                /**
                 * Locale for the labels
                 * @default "fr"
                 */
                locale?: string;
                /** Shop UUID */
                shop_uuid?: ShopUUID;
                /** Field name on which to order as key, asc/desc direction as value. Supported field names: all bucket types. */
                order?: Record<string, any>;
                /** Only return quantities in the selected buckets. */
                bucket_type?: BucketType[];
                /**
                 * Whether to fetch EAN details (brand, label, etc.)
                 * @default true
                 */
                fetchEanDetails?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<EanStocksCollection, Rfc7807>({
                path: `/stock-private/v1/stocks`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description View stocks for a sale id and a brand. For all EANs, the quantities in each bucket are returned.
         *
         * @tags stock view, list
         * @name ViewStocks
         * @summary View stocks
         * @request GET:/stock-private/v1/view-stocks
         * @secure
         */
        viewStocks: (
            query: {
                /** Reduce listing for brand code */
                brand: string;
                /**
                 * Reduce listing for sale id
                 * @min 1
                 */
                sale_id: number;
                /**
                 * Reduce listing for workspace id
                 * @min 1
                 */
                workspace_id?: number;
                /**
                 * pagination offset (for offset-based pagination)
                 * @min 0
                 */
                offset?: number;
                /**
                 * pagination limit (for offset-based pagination)
                 * @min 1
                 * @max 1000
                 */
                limit?: number;
                /**
                 * page number (for page-based navigation)
                 * @min 1
                 */
                page?: number;
                /**
                 * items per page (for page-based navigation)
                 * @min 1
                 * @max 1000
                 */
                itemsPerPage?: number;
                /** Reduce listing for EANs with minimum and / or maximum stock in buckets */
                stocks_filter?: BucketStockRangeFilter[];
                /** Reduce listing for specified EANs */
                eans_filter?: EanCollection;
                /** Reduce listing for specified product codes */
                product_codes_filter?: string[];
                /** Reduce listing for specified model labels */
                labels_filter?: string[];
                /** An update mode. */
                update_mode?: 'Automatic' | 'Blocked' | 'Forced';
                /** Reduce listing for site code */
                site?: string;
                /** Field name on which to order as key, asc/desc direction as value. Supported field names: all bucket types. */
                order?: Record<string, any>;
            },
            params: RequestParams = {}
        ) =>
            this.request<EanStocksCollection, Rfc7807>({
                path: `/stock-private/v1/view-stocks`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Export view stocks as a CSV file.
         *
         * @tags stock view, export
         * @name ExportViewStocks
         * @summary Export view stocks as a CSV file
         * @request GET:/stock-private/v1/view-stocks/export
         * @secure
         */
        exportViewStocks: (
            query: {
                /** Reduce listing for brand code */
                brand: string;
                /**
                 * Reduce listing for sale id
                 * @min 1
                 */
                sale_id: number;
                /** Reduce listing for EANs with minimum and / or maximum stock in buckets */
                stocks_filter?: BucketStockRangeFilter[];
                /** Reduce listing for specified EANs */
                eans_filter?: EanCollection;
                /** Reduce listing for specified product codes */
                product_codes_filter?: string[];
                /** Reduce listing for specified model labels */
                labels_filter?: string[];
                /** Reduce listing for site code */
                site?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<Ean, Rfc7807>({
                path: `/stock-private/v1/view-stocks/export`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),

        /**
         * @description View stocks for a shop UUID. For all EANs, the quantities in each bucket are returned.
         *
         * @tags stock view, list
         * @name ViewShopStocks
         * @summary View shop stocks
         * @request GET:/stock-private/v1/view-shop-stocks
         * @secure
         */
        viewShopStocks: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                /**
                 * pagination offset (for offset-based pagination)
                 * @min 0
                 */
                offset?: number;
                /**
                 * pagination limit (for offset-based pagination)
                 * @min 1
                 * @max 1000
                 */
                limit?: number;
                /**
                 * page number (for page-based navigation)
                 * @min 1
                 */
                page?: number;
                /**
                 * items per page (for page-based navigation)
                 * @min 1
                 * @max 1000
                 */
                itemsPerPage?: number;
                /** Reduce listing for EANs with minimum and / or maximum stock in buckets */
                stocks_filter?: BucketStockRangeFilter[];
                /** Reduce listing for specified EANs */
                eans_filter?: EanCollection;
                /** Reduce listing for specified product codes */
                product_codes_filter?: string[];
                /** Reduce listing for specified model labels */
                labels_filter?: string[];
                /**
                 * Whether to fetch EAN details (brand, label, etc.)
                 * @default true
                 */
                fetchEanDetails?: boolean;
                /**
                 * Locale for the labels
                 * @default "fr"
                 */
                locale?: string;
                /** @default "EUR" */
                currency_code?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<EanStocksCollection, Rfc7807>({
                path: `/stock-private/v1/view-shop-stocks`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Export view shop stocks as a CSV file.
         *
         * @tags stock view, export
         * @name ExportViewShopStocks
         * @summary Export view shop stocks as a CSV file
         * @request GET:/stock-private/v1/view-shop-stocks/export
         * @secure
         */
        exportViewShopStocks: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                /** Reduce listing for EANs with minimum and / or maximum stock in buckets */
                stocks_filter?: BucketStockRangeFilter[];
                /** Reduce listing for specified EANs */
                eans_filter?: EanCollection;
                /** Reduce listing for specified product codes */
                product_codes_filter?: string[];
                /** Reduce listing for specified model labels */
                labels_filter?: string[];
                /**
                 * Locale for the labels
                 * @default "fr"
                 */
                locale?: string;
                /** @default "EUR" */
                currency_code?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<Ean, Rfc7807>({
                path: `/stock-private/v1/view-shop-stocks/export`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),

        /**
         * @description Get the total quantity of EANs in ShopAvailable for a shop UUID.
         *
         * @tags stock view, list
         * @name ViewShopStocksTotal
         * @summary View shop stocks total
         * @request GET:/stock-private/v1/view-shop-stocks/total
         * @secure
         */
        viewShopStocksTotal: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                /** Reduce listing for EANs with minimum and / or maximum stock in buckets */
                stocks_filter?: BucketStockRangeFilter[];
                /** Reduce listing for specified EANs */
                eans_filter?: EanCollection;
                /** Reduce listing for specified product codes */
                product_codes_filter?: string[];
                /** Reduce listing for specified model labels */
                labels_filter?: string[];
            },
            params: RequestParams = {}
        ) =>
            this.request<TotalQuantity, Rfc7807>({
                path: `/stock-private/v1/view-shop-stocks/total`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Get the total quantities of EANs in ShopAvailable and ShopReturned for a shop UUID.
         *
         * @tags stock view, list
         * @name ViewShopStocksTotals
         * @summary View shop stocks totals
         * @request GET:/stock-private/v1/view-shop-stocks/totals
         * @secure
         */
        viewShopStocksTotals: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                /** Reduce listing for EANs with minimum and / or maximum stock in buckets */
                stocks_filter?: BucketStockRangeFilter[];
                /** Reduce listing for specified EANs */
                eans_filter?: EanCollection;
                /** Reduce listing for specified product codes */
                product_codes_filter?: string[];
                /** Reduce listing for specified model labels */
                labels_filter?: string[];
            },
            params: RequestParams = {}
        ) =>
            this.request<TotalQuantities, Rfc7807>({
                path: `/stock-private/v1/view-shop-stocks/totals`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Import stock for a shop UUID. The given quantities replace the current quantities for all given EANs. This operation is done asynchronously.
         *
         * @tags stock update, shop
         * @name ImportShopStock
         * @summary Import shop stock
         * @request POST:/stock-private/v1/import-shop-stock
         * @secure
         */
        importShopStock: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                /** @format uuid */
                stock_movement_id: string;
                'context[retail_operation]'?: string;
            },
            data: EanStocksCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/import-shop-stock`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Import partial inventory for a shop UUID. The given quantities replace the current quantities for all given EANs.   This operation is done synchronously.
         *
         * @tags stock update, shop
         * @name ImportPartialShopInventory
         * @summary Import partial shop inventory
         * @request POST:/stock-private/v1/import-partial-shop-inventory
         * @secure
         */
        importPartialShopInventory: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[retail_operation]'?: string;
                /** @format uuid */
                inventory_id?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/import-partial-shop-inventory`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Import full inventory for a shop UUID. The given quantities replace the current quantities for all given EANs, and quantities for EANs that are not in the payload are set to zero then deleted.   This operation is done asynchronously.
         *
         * @tags stock update, shop
         * @name ImportFullShopInventory
         * @summary Import full shop inventory
         * @request POST:/stock-private/v1/import-full-shop-inventory
         * @secure
         */
        importFullShopInventory: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[retail_operation]'?: string;
                /** @format uuid */
                inventory_id: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/import-full-shop-inventory`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Adjust stock for a shop UUID. The given quantities are added or removed from the stock for all given EANs. This operation is done asynchronously.
         *
         * @tags stock update, shop
         * @name AdjustShopStock
         * @summary Adjust shop stock
         * @request POST:/stock-private/v1/adjust-shop-stock
         * @secure
         */
        adjustShopStock: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                /** @format uuid */
                stock_movement_id?: string;
                'context[retail_order_id]'?: string;
                'context[retail_operation]'?: string;
            },
            data: EanStocksCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/adjust-shop-stock`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when a shop's staff integrates a package from a retail order into the shop's available stock. Increment the value of the bucket 'ShopAvailable' for multiple EANs.
         *
         * @tags stock update, shop
         * @name IntegrateRetailPackage
         * @summary Integrate retail package
         * @request POST:/stock-private/v1/integrate-retail-package
         * @secure
         */
        integrateRetailPackage: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[retail_order_id]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/integrate-retail-package`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when a shop's staff reports that some items are missing. Decrement the value of the bucket 'ShopAvailable' for multiple EANs. If one of the requested quantities is higher than its corresponding current value in the bucket 'ShopAvailable', the current value falls to zero.
         *
         * @tags stock update, shop
         * @name ReportMissingShopStock
         * @summary Report missing shop stock
         * @request POST:/stock-private/v1/report-missing-shop-stock
         * @secure
         */
        reportMissingShopStock: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[retail_operation]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/report-missing-shop-stock`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Delete all stock for a shop UUID. Only call this method if the shop is no longer active, or if its entire stock needs to be replaced by a following call to importShopStock.
         *
         * @tags stock update, shop
         * @name DeleteShopStock
         * @summary Delete shop stock
         * @request POST:/stock-private/v1/delete-shop-stock
         * @secure
         */
        deleteShopStock: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[retail_operation]'?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/delete-shop-stock`,
                method: 'POST',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Import stock for the website. The given quantities replace the current quantities for all given EANs in WebFront (WebAvailable + WebCart). This operation is done asynchronously.
         *
         * @tags stock update
         * @name ImportWebStock
         * @summary Import web stock
         * @request POST:/stock-private/v1/import-web-stock
         * @secure
         */
        importWebStock: (data: EanQuantityCollection, params: RequestParams = {}) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/import-web-stock`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the retail staff initiates the transfer of some stock from a shop to another shop. Decrement the bucket `ShopAvailable` for the given source shop UUID for all EANs. Increment the bucket `RetailShopTransfer` for the given destination shop UUID for all EANs.   The optional stock_movement_id parameter is only here for logs.
         *
         * @tags stock update, stock movement, shop
         * @name CreateShopTransfer
         * @summary Create shop stock transfer
         * @request POST:/stock-private/v1/create-shop-transfer
         * @secure
         */
        createShopTransfer: (
            query: {
                /** Shop UUID */
                source_shop_uuid: ShopUUID;
                /** Shop UUID */
                destination_shop_uuid: ShopUUID;
                /** @format uuid */
                stock_movement_id?: string;
                'context[retail_order_id]'?: string;
                'context[retail_operation]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/create-shop-transfer`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the retail staff cancels the transfer of some stock from a shop to another shop. Increment the bucket `ShopAvailable` for the given source shop UUID for all EANs. Decrement the bucket `RetailShopTransfer` for the given destination shop UUID for all EANs.   The optional stock_movement_id parameter is only here for logs.
         *
         * @tags stock update, stock movement, shop
         * @name CancelShopTransfer
         * @summary Cancel shop stock transfer
         * @request POST:/stock-private/v1/cancel-shop-transfer
         * @secure
         */
        cancelShopTransfer: (
            query: {
                /** Shop UUID */
                source_shop_uuid: ShopUUID;
                /** Shop UUID */
                destination_shop_uuid: ShopUUID;
                /** @format uuid */
                stock_movement_id?: string;
                'context[retail_order_id]'?: string;
                'context[retail_operation]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/cancel-shop-transfer`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the retail staff confirms the transfer of some stock from a shop to another shop. Decrement the bucket `RetailShopTransfer` for the given destination shop UUID for all EANs. Increment the bucket `ShopAvailable` for the given destination shop UUID for all EANs.   If the quantity is higher than the current value in RetailShopTransfer, the value in ShopAvailable is still incremented by the requested quantity.   The optional stock_movement_id parameter is only here for logs.
         *
         * @tags stock update, stock movement, shop
         * @name ConfirmShopTransfer
         * @summary Confirm shop stock transfer
         * @request POST:/stock-private/v1/confirm-shop-transfer
         * @secure
         */
        confirmShopTransfer: (
            query: {
                /** Shop UUID */
                source_shop_uuid: ShopUUID;
                /** Shop UUID */
                destination_shop_uuid: ShopUUID;
                /** @format uuid */
                stock_movement_id?: string;
                'context[retail_order_id]'?: string;
                'context[retail_operation]'?: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/confirm-shop-transfer`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Manually called by IT when there is a need to have accurate values for retail buckets.   Currently only supports syncing `RetailIncomingTransit`. Queries the Retail API for data about orders for the given destination shop UUID, after a given start date. Resets the value of `RetailIncomingTransit` for the given shop UUID for all EANs, then sets new values for EANs that were in the returned list of orders.
         *
         * @tags stock update, shop
         * @name SyncRetailStock
         * @summary Sync retail stock
         * @request POST:/stock-private/v1/sync-retail-stock
         * @secure
         */
        syncRetailStock: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                /** Only return quantities in the selected buckets. */
                bucket_type?: BucketType[];
                /**
                 * The start date and time for retail orders to sync
                 * @format date-time
                 * @example "2023-02-14T09:00:00+01:00"
                 */
                recurrence_end_date: string;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/sync-retail-stock`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Force the handling of the last warehouse update file, even if it has already been handled.
         *
         * @tags stock update, warehouse
         * @name ForceWarehouseStockUpdate
         * @summary Force warehouse stock update
         * @request POST:/stock-private/v1/force-warehouse-stock-update
         * @secure
         */
        forceWarehouseStockUpdate: (params: RequestParams = {}) =>
            this.request<AcceptedOperation, Rfc7807>({
                path: `/stock-private/v1/force-warehouse-stock-update`,
                method: 'POST',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description List scheduled updates and their steps, paginated and optionally filtered by sale id and/or brand.
         *
         * @tags scheduled update, list
         * @name ListScheduledUpdates
         * @summary List scheduled updates
         * @request GET:/stock-private/v1/scheduled-update
         * @secure
         */
        listScheduledUpdates: (
            query?: {
                /** Filter listing by brand code */
                brand?: string;
                /**
                 * Filter listing by sale id
                 * @min 1
                 */
                sale_id?: number;
                /** Filter listing by site code */
                site?: string;
                /** Include terminated schedule updates in listing */
                show_terminated?: boolean;
                /**
                 * pagination offset (for offset-based pagination)
                 * @min 0
                 */
                offset?: number;
                /**
                 * pagination limit (for offset-based pagination)
                 * @min 1
                 * @max 1000
                 */
                limit?: number;
                /**
                 * page number (for page-based navigation)
                 * @min 1
                 */
                page?: number;
                /**
                 * items per page (for page-based navigation)
                 * @min 1
                 * @max 1000
                 */
                itemsPerPage?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<ScheduledUpdateCollection, Rfc7807>({
                path: `/stock-private/v1/scheduled-update`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Create a scheduled update for the given workspace id and sale id. The list of EANs is populated with a call to the CMS API. The scheduled update is marked as being in "step 1". If a value is given for 'recurrence_end_date', then the scheduled update will be recreated and go online every 24 hours until that date, using the same parameters (but with an updated list of EANs).
         *
         * @tags scheduled update
         * @name CreateScheduledUpdate
         * @summary Create scheduled update
         * @request POST:/stock-private/v1/scheduled-update
         * @secure
         */
        createScheduledUpdate: (
            data: {
                /**
                 * The title of the scheduled update.
                 * @minLength 1
                 */
                title: string;
                /** Brand code */
                brand: Brand;
                /** Id of the workspace */
                workspace_id?: number;
                /**
                 * Name of the workspace
                 * @minLength 1
                 */
                workspace_name?: string;
                /**
                 * Start date of the workspace.
                 * @format date-time
                 * @example "2023-02-14T09:30:00+01:00"
                 */
                workspace_start_date?: string;
                /** Id of the sale */
                sale_id: number;
                /**
                 * Name of the sale
                 * @minLength 1
                 */
                sale_name: string;
                /**
                 * When the first step will be executed.
                 * @format date-time
                 * @example "2023-02-14T09:00:00+01:00"
                 */
                scheduled_execution_date: string;
                /**
                 * When the scheduled update will recur for the last time.
                 * @format date-time
                 * @example "2023-02-14T09:00:00+01:00"
                 */
                recurrence_end_date?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperationScheduledUpdateCreated, Rfc7807>({
                path: `/stock-private/v1/scheduled-update`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Show scheduled update and its steps.
         *
         * @tags scheduled update
         * @name ShowScheduledUpdate
         * @summary Show scheduled update
         * @request GET:/stock-private/v1/scheduled-update/{id}
         * @secure
         */
        showScheduledUpdate: (id: number, params: RequestParams = {}) =>
            this.request<ScheduledUpdate, Rfc7807>({
                path: `/stock-private/v1/scheduled-update/${id}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Delete scheduled update and all its related data.
         *
         * @tags scheduled update
         * @name DeletecheduledUpdate
         * @summary Delete scheduled update
         * @request DELETE:/stock-private/v1/scheduled-update/{id}
         * @secure
         */
        deletecheduledUpdate: (id: number, params: RequestParams = {}) =>
            this.request<void, Rfc7807>({
                path: `/stock-private/v1/scheduled-update/${id}`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * @description Update scheduled update. Currently the only property that can be modified this way is the title.
         *
         * @tags scheduled update
         * @name UpdateScheduledUpdate
         * @summary Update scheduled update
         * @request PATCH:/stock-private/v1/scheduled-update/{id}
         * @secure
         */
        updateScheduledUpdate: (
            id: number,
            data: {
                /** The scheduled update's new title. */
                title: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<void, Rfc7807>({
                path: `/stock-private/v1/scheduled-update/${id}`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * @description List a scheduled update's EANs with their update mode settings, optionally filtered by update mode.
         *
         * @tags scheduled update, list
         * @name ListScheduledUpdateEans
         * @summary List a scheduled update's EANs
         * @request GET:/stock-private/v1/scheduled-update/{id}/eans
         * @secure
         */
        listScheduledUpdateEans: (
            id: number,
            query?: {
                /** An update mode. */
                update_mode?: 'Automatic' | 'Blocked' | 'Forced';
            },
            params: RequestParams = {}
        ) =>
            this.request<ScheduledUpdateEanCollection, Rfc7807>({
                path: `/stock-private/v1/scheduled-update/${id}/eans`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Export a list of EANs with the given update mode as a CSV file. If the scheduled update's status is not Online, then that list comes from the current scheduled update with the same sale id. The columns in the CSV provide information about the EANs and some of their stocks.
         *
         * @tags scheduled update, export
         * @name ExportScheduledUpdateEans
         * @summary Export a list of EANs with the given update mode as a CSV file
         * @request GET:/stock-private/v1/scheduled-update/{id}/eans/export
         * @secure
         */
        exportScheduledUpdateEans: (
            id: number,
            query?: {
                /** An update mode. */
                update_mode?: 'Automatic' | 'Blocked' | 'Forced';
            },
            params: RequestParams = {}
        ) =>
            this.request<Ean, Rfc7807>({
                path: `/stock-private/v1/scheduled-update/${id}/eans/export`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),

        /**
         * @description Set security margins for a scheduled update's EANs, with a default security margin and an optional list of values for specific EANs. The scheduled update is then marked as being in "step 2".
         *
         * @tags scheduled update
         * @name SetSecurityMargins
         * @summary Set security margins
         * @request POST:/stock-private/v1/scheduled-update/{id}/security-margins
         * @secure
         */
        setSecurityMargins: (
            id: number,
            data: {
                /**
                 * The default security margin.
                 * @min 0
                 */
                default_security_margin: number;
                /** List of security margins for specific EANs. */
                security_margins?: EanSecurityMarginCollection;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/scheduled-update/${id}/security-margins`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Make the given EANs use the blocked update mode in the scheduled update. The scheduled update is then marked as being in "step 3".
         *
         * @tags scheduled update
         * @name SetEansWithBlockedUpdateMode
         * @summary Set EANs with blocked update mode
         * @request POST:/stock-private/v1/scheduled-update/{id}/eans-blocked-update-mode
         * @secure
         */
        setEansWithBlockedUpdateMode: (id: number, data: EanCollectionBody, params: RequestParams = {}) =>
            this.request<PartiallySuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/scheduled-update/${id}/eans-blocked-update-mode`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Make the given EANs use the forced update mode and set their forced quantities in the scheduled update. The scheduled update is then marked as being in "step 4".
         *
         * @tags scheduled update
         * @name SetEansWithForcedUpdateModeAndForcedQuantities
         * @summary Set EANs with forced update mode and forced quantities
         * @request POST:/stock-private/v1/scheduled-update/{id}/eans-forced-update-mode
         * @secure
         */
        setEansWithForcedUpdateModeAndForcedQuantities: (
            id: number,
            data: EanForcedQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<PartiallySuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/scheduled-update/${id}/eans-forced-update-mode`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Create a stock release step for a scheduled update.
         *
         * @tags scheduled update
         * @name CreateStep
         * @summary Create a stock release step
         * @request POST:/stock-private/v1/scheduled-update/{id}/step
         * @secure
         */
        createStep: (
            id: number,
            data: {
                /**
                 * When the step will be executed.
                 * @format date-time
                 * @example "2023-02-14T09:00:00+01:00"
                 */
                scheduled_execution_date: string;
                /**
                 * Which percentage of available stock will be made available.
                 * @min 1
                 * @max 100
                 */
                availability_percent: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperationEntityCreated, Rfc7807>({
                path: `/stock-private/v1/scheduled-update/${id}/step`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Show a scheduled update step with its planned execution date, and list of quantities for EANs.
         *
         * @tags scheduled update
         * @name ShowScheduledUpdateStep
         * @summary Show a scheduled update step
         * @request GET:/stock-private/v1/scheduled-update/{id}/step/{step_id}
         * @secure
         */
        showScheduledUpdateStep: (id: number, stepId: number, params: RequestParams = {}) =>
            this.request<ScheduledUpdateStepWithEans, Rfc7807>({
                path: `/stock-private/v1/scheduled-update/${id}/step/${stepId}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Update a stock release step for a scheduled update.
         *
         * @tags scheduled update
         * @name UpdateStep
         * @summary Update a stock release step
         * @request PUT:/stock-private/v1/scheduled-update/{id}/step/{step_id}
         * @secure
         */
        updateStep: (
            id: number,
            stepId: number,
            data: {
                /**
                 * When the step will be executed.
                 * @format date-time
                 * @example "2023-02-14T09:00:00+01:00"
                 */
                scheduled_execution_date: string;
                /**
                 * Which percentage of available stock will be made available.
                 * @min 1
                 * @max 100
                 */
                availability_percent: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/scheduled-update/${id}/step/${stepId}`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Delete a stock release step for a scheduled update.
         *
         * @tags scheduled update
         * @name DeleteStep
         * @summary Delete a stock release step
         * @request DELETE:/stock-private/v1/scheduled-update/{id}/step/{step_id}
         * @secure
         */
        deleteStep: (id: number, stepId: number, params: RequestParams = {}) =>
            this.request<void, Rfc7807>({
                path: `/stock-private/v1/scheduled-update/${id}/step/${stepId}`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * @description Fetch a CSV export of the stock of a scheduled update's EANs when it went online. If the scheduled update's status is not Online, then an HTTP 404 error is returned. The columns in the CSV provide information about the EANs and some of their stocks.
         *
         * @tags scheduled update, export
         * @name ExportExecutedScheduledUpdateLastStep
         * @summary Fetch a CSV export of the stock of a scheduled update's EANs when it went online.
         * @request GET:/stock-private/v1/scheduled-update/{id}/export-last-step
         * @secure
         */
        exportExecutedScheduledUpdateLastStep: (id: number, params: RequestParams = {}) =>
            this.request<Ean, Rfc7807>({
                path: `/stock-private/v1/scheduled-update/${id}/export-last-step`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * @description Fetch a CSV export of the stock of a scheduled update's EANs when a given step was executed. If the scheduled update's status is not Online, or the step has not been executed, then an HTTP 404 error is returned. The columns in the CSV provide information about the EANs and some of their stocks.
         *
         * @tags scheduled update, export
         * @name ExportExecutedScheduledUpdateStep
         * @summary Fetch a CSV export of the stock of a scheduled update's EANs when a given step was executed.
         * @request GET:/stock-private/v1/scheduled-update/{id}/step/{step_id}/export
         * @secure
         */
        exportExecutedScheduledUpdateStep: (id: number, stepId: number, params: RequestParams = {}) =>
            this.request<Ean, Rfc7807>({
                path: `/stock-private/v1/scheduled-update/${id}/step/${stepId}/export`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * @description Complete a scheduled update.
         *
         * @tags scheduled update
         * @name CompleteScheduledUpdate
         * @summary Complete a scheduled update
         * @request POST:/stock-private/v1/scheduled-update/{id}/complete
         * @secure
         */
        completeScheduledUpdate: (id: number, params: RequestParams = {}) =>
            this.request<void, Rfc7807>({
                path: `/stock-private/v1/scheduled-update/${id}/complete`,
                method: 'POST',
                secure: true,
                ...params,
            }),

        /**
         * @description Get the current status of automatic adjustment, when it was last updated, and by whom.
         *
         * @tags setting
         * @name ShowAutomaticAdjustmentStatus
         * @summary Show automatic adjustment status
         * @request GET:/stock-private/v1/automatic-adjustment-status
         * @secure
         */
        showAutomaticAdjustmentStatus: (
            query?: {
                /** The warehouse's UUID. */
                warehouse_uuid?: WarehouseUUID;
            },
            params: RequestParams = {}
        ) =>
            this.request<AutomaticAdjustmentStatus, Rfc7807>({
                path: `/stock-private/v1/automatic-adjustment-status`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enable automatic adjustment.
         *
         * @tags setting
         * @name EnableAutomaticAdjustment
         * @summary Enable automatic adjustment
         * @request POST:/stock-private/v1/enable-automatic-adjustment
         * @secure
         */
        enableAutomaticAdjustment: (
            query?: {
                /** The warehouse's UUID. */
                warehouse_uuid?: WarehouseUUID;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/enable-automatic-adjustment`,
                method: 'POST',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Disable automatic adjustment.
         *
         * @tags setting
         * @name DisableAutomaticAdjustment
         * @summary Disable automatic adjustment
         * @request POST:/stock-private/v1/disable-automatic-adjustment
         * @secure
         */
        disableAutomaticAdjustment: (
            query?: {
                /** The warehouse's UUID. */
                warehouse_uuid?: WarehouseUUID;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/disable-automatic-adjustment`,
                method: 'POST',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Get the date of the last automatic adjustment.
         *
         * @tags setting
         * @name ShowAutomaticAdjustmentDate
         * @summary Show the date of the last automatic adjustment
         * @request GET:/stock-private/v1/automatic-adjustment-date
         * @secure
         */
        showAutomaticAdjustmentDate: (params: RequestParams = {}) =>
            this.request<AutomaticAdjustmentDate, Rfc7807>({
                path: `/stock-private/v1/automatic-adjustment-date`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Get the current status of stock update for a warehouse, when it was last updated, and by whom.
         *
         * @tags setting
         * @name ShowWarehouseStockUpdateStatus
         * @summary Show warehouse stock update status
         * @request GET:/stock-private/v1/warehouse-stock-update-status
         * @secure
         */
        showWarehouseStockUpdateStatus: (
            query?: {
                /** The warehouse's UUID. */
                warehouse_uuid?: WarehouseUUID;
            },
            params: RequestParams = {}
        ) =>
            this.request<AutomaticAdjustmentStatus, Rfc7807>({
                path: `/stock-private/v1/warehouse-stock-update-status`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Enable stock update for a warehouse.
         *
         * @tags setting
         * @name EnableWarehouseStockUpdate
         * @summary Enable warehouse stock update
         * @request POST:/stock-private/v1/enable-warehouse-stock-update
         * @secure
         */
        enableWarehouseStockUpdate: (
            query?: {
                /** The warehouse's UUID. */
                warehouse_uuid?: WarehouseUUID;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/enable-warehouse-stock-update`,
                method: 'POST',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Disable stock update for a warehouse.
         *
         * @tags setting
         * @name DisableWarehouseStockUpdate
         * @summary Disable warehouse stock update
         * @request POST:/stock-private/v1/disable-warehouse-stock-update
         * @secure
         */
        disableWarehouseStockUpdate: (
            query?: {
                /** The warehouse's UUID. */
                warehouse_uuid?: WarehouseUUID;
            },
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/disable-warehouse-stock-update`,
                method: 'POST',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Get the date of the last warehouse stock update. If warehouse_uuid is not given, it defaults to the default warehouse's UUID.
         *
         * @tags setting
         * @name ShowWarehouseStockUpdateDate
         * @summary Show the date of the last warehouse stock update
         * @request GET:/stock-private/v1/warehouse-stock-update-date
         * @secure
         */
        showWarehouseStockUpdateDate: (
            query?: {
                /** The warehouse's UUID. */
                warehouse_uuid?: WarehouseUUID;
            },
            params: RequestParams = {}
        ) =>
            this.request<WarehouseStockUpdateDate, Rfc7807>({
                path: `/stock-private/v1/warehouse-stock-update-date`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Get the filename and the time of creation of the diff for the last warehouse stock update. If warehouse_uuid is not given, it defaults to the default warehouse's UUID.
         *
         * @tags setting
         * @name ShowWarehouseStockUpdateFile
         * @summary Show the filename and diff creation date of the last warehouse stock update
         * @request GET:/stock-private/v1/warehouse-stock-update-file
         * @secure
         */
        showWarehouseStockUpdateFile: (
            query?: {
                /** The warehouse's UUID. */
                warehouse_uuid?: WarehouseUUID;
            },
            params: RequestParams = {}
        ) =>
            this.request<WarehouseStockUpdateFile, Rfc7807>({
                path: `/stock-private/v1/warehouse-stock-update-file`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the support staff adds a given quantity of an EAN for customer return repatriation. The quantities are taken from the customer return stock if there is enough quantity, and added  to the repatriation bucket. Move multiple quantities of EANs from the bucket 'RealInWarehouseCustomerReturn'  to the bucket 'RepatriationCustomerReturn' if possible. If the value in the bucket 'RealInWarehouseCustomerReturn' is inferior to the total requested quantity, an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement
         * @name CreateCustomerReturnRepatriation
         * @summary Create customer return repatriation
         * @request POST:/stock-private/v1/create-customer-return-repatriation
         * @secure
         */
        createCustomerReturnRepatriation: (
            query: {
                /** The warehouse's UUID. */
                warehouse_uuid: WarehouseUUID;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/create-customer-return-repatriation`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the support staff adds a given quantity of an EAN for retail return repatriation. The quantities are taken from the retail return stock if there is enough quantity, and added  to the repatriation bucket. Move multiple quantities of EANs from the bucket 'RealInWarehouseRetailReturn'  to the bucket 'RepatriationRetailReturn' if possible. If the value in the bucket 'RealInWarehouseRetailReturn' is inferior to the total requested quantity, an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement
         * @name CreateRetailReturnRepatriation
         * @summary Create retail return repatriation
         * @request POST:/stock-private/v1/create-retail-return-repatriation
         * @secure
         */
        createRetailReturnRepatriation: (
            query: {
                /** The warehouse's UUID. */
                warehouse_uuid: WarehouseUUID;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/create-retail-return-repatriation`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when the support staff adds a given quantity of an EAN for retail supply repatriation. The quantities are taken from the retail supply stock if there is enough quantity, and added  to the repatriation bucket. Move multiple quantities of EANs from the bucket 'RealInWarehouseRetailSupply'  to the bucket 'RepatriationRetailSupply' if possible. If the value in the bucket 'RealInWarehouseRetailSupply' is inferior to the total requested quantity, an HTTP 409 error is returned, and the transaction is rolled back.
         *
         * @tags stock movement
         * @name CreateRetailSupplyRepatriation
         * @summary Create retail supply repatriation
         * @request POST:/stock-private/v1/create-retail-supply-repatriation
         * @secure
         */
        createRetailSupplyRepatriation: (
            query: {
                /** The warehouse's UUID. */
                warehouse_uuid: WarehouseUUID;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<SuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/create-retail-supply-repatriation`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when a recorded customer return repatriation is canceled. Move multiple quantities of EANs from the bucket 'RepatriationCustomerReturn' to the bucket 'RealInWarehouseCustomerReturn'. If one of the requested quantities is higher than its corresponding current value in the bucket 'RepatriationCustomerReturn', the current value falls to zero. The quantities that could not be moved are returned in the "failure.eans" key.
         *
         * @tags stock movement
         * @name CancelCustomerReturnRepatriation
         * @summary Cancel customer return repatriation
         * @request POST:/stock-private/v1/cancel-customer-return-repatriation
         * @secure
         */
        cancelCustomerReturnRepatriation: (
            query: {
                /** The warehouse's UUID. */
                warehouse_uuid: WarehouseUUID;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/cancel-customer-return-repatriation`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when a recorded retail return repatriation is canceled. Move multiple quantities of EANs from the bucket 'RepatriationRetailReturn' to the bucket 'RealInWarehouseRetailReturn'. If one of the requested quantities is higher than its corresponding current value in the bucket 'RepatriationRetailReturn', the current value falls to zero. The quantities that could not be moved are returned in the "failure.eans" key.
         *
         * @tags stock movement
         * @name CancelRetailReturnRepatriation
         * @summary Cancel retail return repatriation
         * @request POST:/stock-private/v1/cancel-retail-return-repatriation
         * @secure
         */
        cancelRetailReturnRepatriation: (
            query: {
                /** The warehouse's UUID. */
                warehouse_uuid: WarehouseUUID;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/cancel-retail-return-repatriation`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Called when a recorded retail supply repatriation is canceled. Move multiple quantities of EANs from the bucket 'RepatriationRetailSupply' to the bucket 'RealInWarehouseRetailSupply'. If one of the requested quantities is higher than its corresponding current value in the bucket 'RepatriationRetailSupply', the current value falls to zero. The quantities that could not be moved are returned in the "failure.eans" key.
         *
         * @tags stock movement
         * @name CancelRetailSupplyRepatriation
         * @summary Cancel retail supply repatriation
         * @request POST:/stock-private/v1/cancel-retail-supply-repatriation
         * @secure
         */
        cancelRetailSupplyRepatriation: (
            query: {
                /** The warehouse's UUID. */
                warehouse_uuid: WarehouseUUID;
            },
            data: EanQuantityCollection,
            params: RequestParams = {}
        ) =>
            this.request<QuantityAwarePartiallySuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/cancel-retail-supply-repatriation`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Make the given EANs use the given update modes and quantities, or set their quantities to the maximum possible depending on the chosen update modes: * automatic: set WebAvailable to the maximum possible quantity * blocked: set the given quantity * forced: set the given quantity The change in update modes are saved for the next time a scheduled update is scheduled for a given sale, to avoid repeating mistakes. The change in quantity affects WebAvailable and WebCart.
         *
         * @tags stock update
         * @name AddEanUpdateModeCorrections
         * @summary Add update mode and quantity corrections to EANs
         * @request POST:/stock-private/v1/ean/corrections
         * @secure
         */
        addEanUpdateModeCorrections: (data: EanUpdateModeCorrectionCollection, params: RequestParams = {}) =>
            this.request<PartiallySuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/ean/corrections`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Set the given EANs' quantities in shop stock, by updating the value of ShopAvailable.
         *
         * @tags stock update, shop
         * @name AddEanShopStockCorrections
         * @summary Add quantity corrections to EANs in shop stock
         * @request POST:/stock-private/v1/ean/shop-stock-corrections
         * @secure
         */
        addEanShopStockCorrections: (
            query: {
                /** Shop UUID */
                shop_uuid: ShopUUID;
                'context[retail_operation]'?: string;
            },
            data: EanShopStockCorrectionCollection,
            params: RequestParams = {}
        ) =>
            this.request<PartiallySuccessfulOperation, Rfc7807>({
                path: `/stock-private/v1/ean/shop-stock-corrections`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Return the list of EANs of a list of product codes and/or labels, and their details.
         *
         * @tags stock view, list
         * @name ListEans
         * @summary List EANs and their details
         * @request GET:/stock-private/v1/ean
         * @secure
         */
        listEans: (
            query?: {
                product_code?: string[];
                label?: string[];
                brand?: string[];
            },
            params: RequestParams = {}
        ) =>
            this.request<EanDetailsCollection, Rfc7807>({
                path: `/stock-private/v1/ean`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),
    };
}
