import { produce } from 'immer';
import { schema } from '@actions/storeVariants/config';
import { TYPES as LIST_TYPES } from '@actions/storeVariants/list';
import { TYPES as DELETE_CATALOG_VARIANT_IN_WORKSPACE_TYPES } from '@actions/catalogVariants/deleteInWorkspace';
import { TYPES as PATCH_TYPES } from '@actions/storeVariants/patch';
import { TYPES as ASYNC_EXPORT_TYPES } from '@actions/storeVariants/generateCsv';
import { CUSTOM_VIEW, DEFAULT_VIEW, EDITORIAL_VIEW, PRESETS } from '@containers/products/list/configuration';
import { CHANGE_LIST_CONFIGURATION, CHANGE_LIST_VIEW } from '@actions/storeVariants/configuration';
import { TYPES as PATCH_CATALOG_VARIANT_TYPES } from '@actions/catalogVariants/patch';
import { CRUD_INITIAL_STATE, reduceReducers, listReducer, patchReducer, asyncExportReducer } from './crud';
import {
    BULLET_POINTS_VIEW,
    FINAL_PRICE_VIEW,
    PRODUCTS_TRANSLATIONS_VIEW,
} from '@containers/products/list/configuration/builder';
import { IGNORED_LOCALES } from '@utils/locales';

export const INITIAL_STATE = {
    ...CRUD_INITIAL_STATE,
    listConfiguration: PRESETS[DEFAULT_VIEW],
    listView: DEFAULT_VIEW,
};

const deleteCatalogVariantInWorkspaceReducer = (state, action) => {
    switch (action.type) {
        case DELETE_CATALOG_VARIANT_IN_WORKSPACE_TYPES.DELETE_IN_WORKSPACE_SUCCESS: {
            const entities = {};
            let total = state.total;
            const { id, workspaceId } = action.meta.previousAction;
            Object.keys(state.entities).forEach(variantId => {
                const variant = state.entities[variantId];
                if (variant.catalogVariant.id !== id || !variant.workspacesIds.includes(workspaceId)) {
                    entities[variantId] = variant;
                } else {
                    total--;
                }
            });

            return {
                ...state,
                entities,
                total,
                loading: false,
            };
        }

        default:
            return state;
    }
};

const patchCatalogVariant = (state, action) => {
    switch (action.type) {
        case PATCH_CATALOG_VARIANT_TYPES.PATCH_SUCCESS: {
            const catalogVariant = action.payload.rawData;

            return produce(state, draftState => {
                const stateStoreVariant = draftState.entities[catalogVariant.selectedStoreVariant.id];
                if (stateStoreVariant) {
                    // Only update properties that can be modified on product list to avoid missing data
                    stateStoreVariant.catalogVariant.prices = catalogVariant.prices;
                    stateStoreVariant.catalogVariant.translations = catalogVariant.translations;
                    stateStoreVariant.catalogVariant.sizeAdviceType = catalogVariant.sizeAdviceType;
                    stateStoreVariant.catalogVariant.modelSizingType = catalogVariant.modelSizingType;
                    stateStoreVariant.catalogVariant.pimWrapper = catalogVariant.pimWrapper;
                }

                return draftState;
            });
        }

        default:
            return state;
    }
};

const configurationReducer = (state, action) => {
    switch (action.type) {
        case CHANGE_LIST_CONFIGURATION: {
            return {
                ...state,
                listConfiguration: {
                    ...state.listConfiguration,
                    ...action.values,
                },
                listView: CUSTOM_VIEW,
            };
        }
        case CHANGE_LIST_VIEW: {
            const listView = action.view;
            const locales = action.locales.filter(locale => !IGNORED_LOCALES.includes(locale.code));
            const currencies = action.currencies;
            const viewChanged = state.listView !== listView;
            let listConfiguration = viewChanged ? PRESETS[listView] : state.listConfiguration;

            if (listView === EDITORIAL_VIEW) {
                listConfiguration = {
                    ...listConfiguration,
                    ...locales.reduce(
                        (acc, locale) => ({
                            ...acc,
                            [`edito_${locale.code}`]: true,
                        }),
                        {}
                    ),
                };
            }

            if (listView === FINAL_PRICE_VIEW) {
                listConfiguration = {
                    ...listConfiguration,
                    ...currencies.reduce(
                        (acc, currency) => ({
                            ...acc,
                            [`price_${currency.code}`]: true,
                        }),
                        {}
                    ),
                };
            }

            if (listView === BULLET_POINTS_VIEW) {
                listConfiguration = {
                    ...listConfiguration,
                    ...locales.reduce(
                        (acc, locale) => ({
                            ...acc,
                            [`bullet_points_${locale.code}`]: true,
                        }),
                        {}
                    ),
                };
            }

            if (listView === PRODUCTS_TRANSLATIONS_VIEW) {
                listConfiguration = {
                    ...listConfiguration,
                    ...locales.reduce(
                        (acc, locale) => ({
                            ...acc,
                            [`products_translations_${locale.code}`]: true,
                        }),
                        {}
                    ),
                };
            }

            return {
                ...state,
                listView,
                listConfiguration,
            };
        }

        default:
            return state;
    }
};

export default reduceReducers(
    configurationReducer,
    deleteCatalogVariantInWorkspaceReducer,
    patchReducer(PATCH_TYPES, schema, true),
    listReducer(LIST_TYPES, schema),
    patchCatalogVariant,
    asyncExportReducer(ASYNC_EXPORT_TYPES),
    INITIAL_STATE
);
